import React from "react";
import ReactDOM from "react-dom";
import { AppContent } from "./app-content";
import { theme } from "@chakra-ui/core";
import { colors } from "./custom-theme"; // custom theme
import { ThemeProvider, CSSReset } from "@chakra-ui/core";
import qs from "qs";
import "./app.scss";
import { setAuthHeaderDefault, configAxiosDefaults } from "./common/request";
import * as Sentry from "@sentry/react";
import { Workbox } from "workbox-window";
import { addSeconds, formatISO, isAfter, parseISO } from "date-fns";
import Axios from "axios";

window.onerror = function(error) {
    console.error(error);
    const resizeObserverLoopErrRe = /^[^(ResizeObserver loop limit exceeded)]/;
    if (resizeObserverLoopErrRe.test(error.message)) {
        return;
    }
    window.alert("Onvoorziene fout. Probeer opnieuw.");
};

if (window.appConfig && window.appConfig.sentryDsn) {
    Sentry.init({
        dsn: window.appConfig.sentryDsn,
        environment: window.appConfig.sentryEnv
    });
}

configAxiosDefaults();

function render() {
    const customTheme = {
        ...theme,

        colors: {
            ...theme.colors,
            ...colors
        }
    };

    if (window.appConfig.serviceWorker) {
        var css = "background: blue;color: white; padding: 0 4px;";
        if ("serviceWorker" in navigator) {
            console.log("%cRegister service worker", css);
            // Use the window load event to keep the page load performant
            window.addEventListener("load", () => {
                const wb = new Workbox("/service-worker.js");
                wb.addEventListener("waiting", event => {
                    console.log(
                        `%cA new service worker has installed, but it can't activate` +
                            `until all tabs running the current version have fully unloaded.`,
                        css
                    );
                });
                wb.addEventListener("installed", event => {
                    if (event.isUpdate) {
                        alert("Nieuwe versie beschikbaar. Klik OK om de nieuwe versie te laden.");

                        navigator.serviceWorker
                            .getRegistrations()
                            .then(function(registrations) {
                                for (let registration of registrations) {
                                    console.log(`%cUnregister serviceworker`, css);
                                    registration.unregister();
                                }
                                window.location.reload(true);
                            })
                            .catch(function(err) {
                                console.error("Service Worker unregister failed: ", err);
                            });
                    }
                    if (!event.isUpdate) {
                        console.log("%cService worker installed.", css);
                    }
                });
                wb.addEventListener("activated", event => {
                    console.log("%cService worker activated.", css);
                });

                wb.register();
                //navigator.serviceWorker.register("/service-worker.js");
            });
        }
    } else {
        console.log("Do NOT Register service worker");
    }

    ReactDOM.render(
        <ThemeProvider theme={customTheme}>
            <CSSReset />
            <AppContent></AppContent>
        </ThemeProvider>,
        document.getElementById("root")
    );
}

if (document.getElementById("root")) {
    // save access token if we are redirected after login
    var tok = qs.parse(window.location.hash.substring(1)); // url is like https://xenex.test/#/access_token=xxxxxxx
    if (tok.access_token) {
        // add calculated expiry date
        tok.expiry_date = formatISO(addSeconds(new Date(), tok.expires_in));
        // token in URL (after login)
        window.localStorage.setItem("token", JSON.stringify(tok));
        setAuthHeaderDefault(tok.access_token);
        Axios.get("/api/user/info").then(res => {
            window.localStorage.setItem("user_info", JSON.stringify(res.data));
            render();
        });
    } else {
        // token saved, and already logged in?
        // important. this only saves the token in the UI thread. Web worker needs another default set
        var token = window.localStorage.getItem("token");
        if (token) {
            var tokenObj = JSON.parse(token);
            if (isAfter(new Date(), parseISO(tokenObj.expiry_date))) {
                console.log(
                    "Token expired, remove from local storage and reload. Reload will redirect for a new token."
                );
                localStorage.removeItem("token");
                localStorage.removeItem("user_info");
                window.location.reload();
            }
            setAuthHeaderDefault(tokenObj.access_token);
        }
        render();
    }
}
