import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Layout, LayoutBody, LayoutSidebar } from "../../components/layout";
import { SettingsList } from "../../components/settings/settings-list";
import { UsersSettings } from "./users-settings";
import { UserEdit } from "./user-edit";
import { VisitorReportsSettings } from "./visitors-report-settings";
import { CompanyTypesSettings } from "./company-types-settings";
import { ChangePassword } from "./change-password";
import { css } from "emotion";

const SettingsPage = () => {
    return (
        <Layout title="Instellingen">
            <LayoutSidebar style={{ width: "200px" }}>
                <SettingsList />
            </LayoutSidebar>
            <LayoutBody>
                <Switch>
                    <Route path={"/settings/users/:id/edit"}>
                        <UserEdit />
                    </Route>
                    <Route path={"/settings/users"}>
                        <UsersSettings />
                    </Route>
                    <Route path={"/settings/visitors-report"} component={VisitorReportsSettings} />
                    <Route path={"/settings/company-types"} component={CompanyTypesSettings} />
                    <Route path={"/settings/change-password"} component={ChangePassword} />
                    <Redirect to={"/settings/users"} />
                </Switch>
            </LayoutBody>
        </Layout>
    );
};

export { SettingsPage };
