import React, { useState } from "react";
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    ButtonGroup,
    Textarea,
    FormControl,
    FormLabel,
    Input
} from "@chakra-ui/core";
import { useEffect } from "react";
import { db } from "../../services/db";

const CompanyReportModal = ({ isOpen, visitId, onClose }) => {
    const [data, setData] = useState({});

    useEffect(() => {
        async function fetch() {
            if (!visitId) return;
            var tmp = await db.visits.get(visitId);
            setData(tmp);
        }
        fetch();
    }, [visitId]);

    return (
        <Modal isOpen={!!visitId} size="xl" onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Bezoekrapport</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {data.report_content &&
                        data.report_content.questions.map((question, questionIndex) => (
                            <div key={questionIndex}>
                                {question.type === "text" && (
                                    <FormControl>
                                        <FormLabel>{question.question}</FormLabel>
                                        <Textarea
                                            isReadOnly={true}
                                            defaultValue={question.response}
                                        />
                                    </FormControl>
                                )}
                                {question.type === "number" && (
                                    <FormControl>
                                        <FormLabel>{question.question}</FormLabel>
                                        <Input isReadOnly={true} defaultValue={question.response} />
                                    </FormControl>
                                )}
                                {question.type === "multiple_choice" && (
                                    <FormControl>
                                        <FormLabel>{question.question}</FormLabel>
                                        <Input isReadOnly={true} defaultValue={question.response} />
                                    </FormControl>
                                )}
                            </div>
                        ))}
                </ModalBody>

                <ModalFooter>
                    <ButtonGroup size="sm">
                        <Button variantColor="green" onClick={onClose}>
                            Sluiten
                        </Button>
                    </ButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export { CompanyReportModal };
