import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { css } from "emotion";
import { colors } from "../../custom-theme";
import { Spinner } from "@chakra-ui/core";
import { FiUser, FiUserCheck } from "react-icons/fi";
import { useLocationChange } from "../../common/hooks";
import { getCompany } from "../../services/company-service";
import styled from "@emotion/styled";
import { formatDate } from "../../helpers/format";

const CompanyHeaderStyles = css`
    display: flex;
    margin-bottom: 12px;
    flex-shrink: 0;

    .info {
        line-height: 1;

        h1 {
            display: block;
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 4px;
        }

        span {
            display: block;
            font-size: 12px;
            color: ${colors.gray[500]};
        }
    }

    .buttons {
        flex-shrink: 0;
    }
`;

const CompanyRecordType = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "100%",
    backgroundColor: props.longVisit ? colors.red[100] : "#b9f1cb",
    color: props.longVisit ? colors.red[400] : colors.green[600],
    transform: "translateY(2px)",
    marginRight: "10px",
    svg: {
        transform: props.type == "customer" && "translateY(1px)"
    }
}));

const CompanyHeader = () => {
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const match = useRouteMatch();

    useLocationChange(() => {
        async function fetch() {
            if (match.params.id === "new") {
                setCompany({
                    record_type: "prospect",
                    name: "Nieuwe prospect"
                });
            } else {
                var model = await getCompany(match.params.id);
                setCompany(model);
            }
            setLoading(false);
        }
        fetch();
    });

    return (
        <>
            {loading && (
                <div
                    style={{
                        marginBottom: "12px",
                        minHeight: "40px"
                    }}
                >
                    <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="red.500"
                        size="md"
                    />
                </div>
            )}

            {!loading && company && (
                <div className={CompanyHeaderStyles}>
                    <CompanyRecordType type={company.record_type} longVisit={company.longVisit}>
                        {company.record_type == "customer" ? <FiUserCheck /> : <FiUser />}
                    </CompanyRecordType>
                    <div className="info">
                        <h1>{company.name}</h1>
                        <span>
                            Laatste bezoek:{" "}
                            {!!company.last_visit ? formatDate(company.last_visit) : "-"}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

export { CompanyHeader };
