import React, { useEffect } from "react";
import { RecoilRoot, useRecoilTransactionObserver_UNSTABLE } from "recoil";
import { HashRouter as Router } from "react-router-dom";
import { Menu, MenuItem } from "./components/menu";
import { Routes } from "./helpers/routes";
import { FiBox, FiUsers, FiSettings, FiLogOut, FiTrash2 } from "react-icons/fi";
import { isAdmin } from "./common/user-info";
import { css } from "emotion";
import { useConnection } from "./common/hooks";
import { useState } from "react";
import { db } from "./services/db";
import { updateUserLocation } from "./common/location";

const AppContent = props => {
    const admin = isAdmin();
    const [hasToken, setHasToken] = useState(false);
    const connectionState = useConnection();

    useEffect(() => {
        var token = window.localStorage.getItem("token");
        // todo: check expiration date
        if (!token) {
            window.location.href = "/redirect";
            return;
        }

        setHasToken(true);

        updateUserLocation();
        var handler = window.setInterval(() => {
            updateUserLocation();
        }, 60000); // every minute

        return () => {
            window.clearInterval(handler);
        };
    }, []);

    const handleLogout = () => {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("current_visit");
        window.localStorage.removeItem("user_info");

        window.location.href = "/logout";
    };

    const handleRemoveSync = e => {
        if (
            window.confirm(
                "Bent u zeker? Deze functionaliteit wist alle gesynchroniseerde data op je device. \nEen nieuwe synchronisatie vanaf nul is hierna verplicht."
            )
        ) {
            db.delete();
            window.localStorage.removeItem("column_settings");
            window.localStorage.removeItem("last_sync_date");
            window.localStorage.removeItem("visitor_report");
            window.localStorage.removeItem("current_visit");
            window.localStorage.removeItem("cart");
            window.localStorage.removeItem("camera_device");
            window.location.href = "/";
        }
    };

    return (
        <RecoilRoot>
            {hasToken && (
                <Router>
                    <Menu>
                        <MenuItem to="/companies" icon={FiUsers} title="Bedrijven" />
                        <MenuItem to="/products" icon={FiBox} title="Producten" />
                        <div
                            className={css`
                                margin-top: auto;
                            `}
                        >
                            <MenuItem
                                isDisabled={!connectionState.online}
                                onClick={handleRemoveSync}
                                icon={FiTrash2}
                                title="Verwijderen sync data"
                            />
                            {admin && (
                                <MenuItem
                                    isDisabled={!connectionState.online}
                                    to="/settings"
                                    icon={FiSettings}
                                    title="Instellingen"
                                />
                            )}

                            <MenuItem
                                isDisabled={!connectionState.online}
                                onClick={handleLogout}
                                icon={FiLogOut}
                                title="Uitloggen"
                            />
                        </div>
                    </Menu>
                    <Routes />
                </Router>
            )}
        </RecoilRoot>
    );
};

export { AppContent };
