import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import qs from "qs";
import { useInterval, usePrevious } from "react-use";
import { scannerItemsAtom } from "../atoms";
import { useRecoilState } from "recoil";

function useQueryParams() {
    const location = useLocation();

    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    return params;
}

function useLocationChange(callback) {
    const location = useLocation();
    const prevLocation = usePrevious(location);

    useEffect(() => {
        callback(location, prevLocation);
    }, [location]);
}

function useConnection() {
    const [conn, setConn] = useState({ online: window.navigator.onLine });
    useInterval(() => {
        if (conn.online !== window.navigator.onLine) {
            setConn({ online: window.navigator.onLine });
        }
    }, 1000);

    return conn;
}

// Hook
function useDebounceEffect(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}

function useOnScreen(ref, rootMargin = "0px") {
    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when observer callback fires
                setIntersecting(entry.isIntersecting);
            },
            {
                rootMargin
            }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            observer.unobserve(ref.current);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return isIntersecting;
}

function useScannerItems() {
    const [scannerItems, setScannerItems] = useRecoilState(scannerItemsAtom);

    useEffect(() => {
        window.localStorage.setItem("scanner_items", JSON.stringify(scannerItems));
    }, [scannerItems]);

    return [scannerItems, setScannerItems];
}

export {
    useLocationChange,
    useQueryParams,
    useConnection,
    useDebounceEffect,
    useOnScreen,
    useScannerItems
};
