import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { SettingsPanelForm } from "../../components/panel";
import { ButtonGroup, Button, Stack, useToast } from "@chakra-ui/core";
import { FiCheck } from "react-icons/fi";
import { InputFormControl } from "../../components/controls";
import Axios from "axios";

const ChangePassword = () => {
    const [isSaving, setIsSaving] = useState(false);
    const toast = useToast();

    const onSubmit = async (values, form) => {
        try {
            setIsSaving(true);
            await Axios.post("/api/user/change-password", values);
            setIsSaving(false);

            setTimeout(form.reset);
            toast({
                title: "Gebruiker",
                description: "Wachtwoord gewijzigd.",
                status: "success",
                isClosable: true
            });
        } catch (error) {
            console.log("ERROR", error.response.data.errors);
            setIsSaving(false);

            return error.response.data.errors;
        }
    };

    return (
        <SettingsPanelForm
            title="Wachtwoord wijzigen"
            onSubmit={onSubmit}
            actions={
                <ButtonGroup size="sm">
                    <Button
                        isLoading={isSaving}
                        type="submit"
                        leftIcon={FiCheck}
                        variantColor="green"
                    >
                        Opslaan
                    </Button>
                </ButtonGroup>
            }
            render={({ handleSubmit, reset, submitting, dirty, pristine, values }) => (
                <Stack spacing="2">
                    <InputFormControl
                        label="Huidig wachtwoord"
                        name="current_password"
                        type="password"
                    />
                    <InputFormControl label="Nieuw wachtwoord" name="password" type="password" />
                    <InputFormControl
                        label="Bevestig wachtwoord"
                        name="password_confirmation"
                        type="password"
                    />
                </Stack>
            )}
        />
    );
};

export { ChangePassword };
