import React, { useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import { css } from "emotion";
import { colors } from "../custom-theme";

const TableStyles = css`
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    font-size: 14px;

    thead {
        background-color: ${colors.gray[50]};

        th {
            svg {
                display: inline-block;
                margin-left: 3px;
                transform: translateY(-1px);
            }

            &:last-child {
                text-align: right;
            }
        }
    }

    th,
    td {
        border-collapse: collapse;
        padding: 10px;
    }

    tbody {
        td {
            border-bottom: 1px solid ${colors.gray[100]};
            width: 1px;
            white-space: nowrap;

            .status {
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 100%;
            }
            a:not([type="button"]) {
                color: ${colors.blue[400]};
            }

            &:last-child {
                /* width: 1px;
                white-space: nowrap; */
                text-align: right;
            }
        }
    }
`;

const Table = ({ columns, data, onSort }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            disableMultiSort: true,
            manualSortBy: true,
            initialState: {
                sortBy: [
                    {
                        id: "date",
                        desc: false
                    },
                    {
                        id: "name",
                        desc: false
                    }
                ]
            }
        },
        useSortBy
    );

    useEffect(() => {
        onSort(sortBy);
    }, [onSort, sortBy]);

    return (
        <table {...getTableProps()} className={TableStyles}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                style={{ textAlign: column.id === "total" ? "right" : null }}
                            >
                                {column.render("Header")}
                                {/* Add a sort direction indicator */}
                                {column.isSorted ? (
                                    column.isSortedDesc ? (
                                        <FiChevronDown />
                                    ) : (
                                        <FiChevronUp />
                                    )
                                ) : (
                                    ""
                                )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        style={{
                                            textAlign: cell.column.id === "total" ? "right" : null
                                        }}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export { Table };
