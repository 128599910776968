const deepCopy = value => {
    return JSON.parse(JSON.stringify(value));
};

function debounce(func, wait = 400) {
    let timeout;
    return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func.apply(this, args);
        }, wait);
    };
}

export { deepCopy, debounce };
