import React from "react";
import { useCart, useCurrentVisit, defaultCart, defaultVisit } from "../common/current-visit";
import { css } from "emotion";
import { BiBasket } from "react-icons/bi";
import { colors } from "../custom-theme";
import { Box, useToast } from "@chakra-ui/core";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { deepCopy } from "../common/utils";
import { formatISO } from "date-fns";
import { db } from "../services/db";
import { useRecoilState } from "recoil";
import { reportModalState } from "../atoms";
import { VisitorReportModal } from "./company/visitor-report-modal";
import { updateUserLocation } from "../common/location";

const CurrentVisit = props => {
    const [isOpenVisitorReport, setIsOpenVisitorReport] = useRecoilState(reportModalState);
    const [currentVisit, setCurrentVisit] = useCurrentVisit();
    const [cart, setCart] = useCart();
    const toast = useToast();
    const location = useLocation();
    const history = useHistory();

    const endCurrentVisit = async report => {
        // update the visit state
        await db.newVisits.update(currentVisit.id, {
            report: report,
            end_time: formatISO(new Date()),
            active: false
        });

        setCurrentVisit(deepCopy(defaultVisit));
        setCart(deepCopy(defaultCart));

        toast({
            title: "Bezoek",
            description: "Bezoek beëindigd.",
            status: "success",
            isClosable: true
        });

        if (location.pathname === "/cart") {
            // redirect from cart if we're on that page
            history.push("/companies");
        }
    };

    const onCloseVisitorReport = result => e => {
        if (!result) {
            // we cancelled
            setIsOpenVisitorReport(false);
            return;
        }

        var hasError = false;
        var errors = {};
        var c = 0;
        for (var question of result.questions) {
            if (!question.response) {
                hasError = true;
                errors[`questions[${c}].response`] = "Verplicht in te vullen";
            }
            c++;
        }
        if (hasError) {
            toast({
                title: "Bezoek",
                description: "Gelieve alle vragen in te vullen.",
                status: "error",
                isClosable: true
            });
            return errors;
        }

        setIsOpenVisitorReport(false);

        endCurrentVisit(result);

        updateUserLocation();
        history.push("/companies");
    };

    return (
        <>
            {currentVisit.active && currentVisit.company.record_type === "customer" && (
                <Box
                    as={NavLink}
                    to="/cart"
                    className={css`
                        position: fixed;
                        background: ${colors.red[500]};
                        padding: 20px;
                        border-radius: 50%;
                        right: 30px;
                        bottom: 30px;
                        z-index: 1000;
                        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.14);
                        cursor: pointer;
                        :hover {
                            background: ${colors.red[600]};
                        }
                    `}
                >
                    <BiBasket size={22} color="white"></BiBasket>
                </Box>
            )}
            <VisitorReportModal isOpen={isOpenVisitorReport} onClose={onCloseVisitorReport} />
        </>
    );
};

export { CurrentVisit };
