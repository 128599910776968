import React from "react";
import { NavLink } from "react-router-dom";
import { css } from "emotion";
import { colors } from "../custom-theme";
import cl from "classnames";
import { Box, Button } from "@chakra-ui/core";

const MenuStyles = css`
    display: flex;
    flex-direction: column;
    background: ${colors.red[500]};
    width: 74px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    flex-shrink: 0;
`;

const MenuItemStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 74px;
    user-select: none;
    color: white;
    flex-shrink: 0;
    transition: all 0.2s ease;

    &:not(:last-child) {
        border-bottom: 1px solid ${colors.red[600]};
    }

    svg {
        width: 24px;
        height: 24px;
    }

    &.active {
        /* background: blue; */
        background-color: ${colors.red[600]};
    }
    &:first-child {
        border-top: 1px solid ${colors.red[600]};
    }
    &.disabled {
        opacity: 0.4;
    }
`;

const Menu = ({ children, ...rest }) => {
    return (
        <div className={MenuStyles} {...rest}>
            {children}
        </div>
    );
};

const MenuItem = ({ to, isDisabled, href, icon, ...rest }) => {
    if (to) {
        return (
            <Box
                as={isDisabled ? "div" : NavLink}
                className={cl(MenuItemStyles, { disabled: isDisabled })}
                to={to}
                {...rest}
            >
                {icon()}
            </Box>
        );
    } else if (href) {
        return (
            <Box
                as={isDisabled ? "div" : "a"}
                className={cl(MenuItemStyles, { disabled: isDisabled })}
                href={href}
                {...rest}
            >
                {icon()}
            </Box>
        );
    } else {
        return (
            <Box
                as={isDisabled ? "div" : "button"}
                className={cl(MenuItemStyles, { disabled: isDisabled })}
                href={href}
                {...rest}
            >
                {icon()}
            </Box>
        );
    }
};

export { Menu, MenuItem };
