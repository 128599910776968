function getUserInfo() {
    var tmp = window.localStorage.getItem("user_info");
    if (tmp) return JSON.parse(tmp);

    return null;
}

function getUserRoles() {
    var tmp = getUserInfo();
    if (tmp) return tmp.roles;

    return null;
}

function isAdmin() {
    var tmp = getUserInfo();
    if (tmp) return tmp.roles.indexOf("admin") >= 0;

    return false;
}

function isInternal() {
    var tmp = getUserInfo();
    if (tmp) return tmp.roles.indexOf("internal") >= 0;

    return false;
}

export { getUserInfo, getUserRoles, isInternal, isAdmin };
