import React from "react";
import { useField } from "react-final-form";
import {
    Input,
    FormLabel,
    FormControl,
    Checkbox,
    Switch,
    Flex,
    Textarea,
    NumberInput,
    InputLeftElement,
    IconButton,
    NumberDecrementStepper,
    NumberInputField,
    NumberIncrementStepper,
    InputRightElement,
    RadioGroup,
    CheckboxGroup,
    Box,
    Radio,
    FormErrorMessage,
    Select
} from "@chakra-ui/core";
import { FiMinus, FiPlus } from "react-icons/fi";
import { css } from "emotion";
import { colors } from "../custom-theme";

const CheckboxGroupControl = ({ children, label, ...rest }) => {
    return (
        <Box {...rest}>
            <FormLabel>{label}</FormLabel>
            <CheckboxGroup>{children}</CheckboxGroup>
        </Box>
    );
};

const RadioGroupControl = ({ input, meta, label, children }) => (
    <FormControl isInvalid={meta.touched && meta.invalid} my={4}>
        <FormLabel htmlFor={input.name}>{label}</FormLabel>
        <RadioGroup {...input}>{children}</RadioGroup>
        <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
);

const CheckboxControl = ({ children, name, label, type, ...rest }) => {
    const { input, meta } = useField(name, {
        type: "checkbox" // important for RFF to manage the checked prop
    });
    return (
        <Checkbox isChecked={input.checked} {...input} isInvalid={meta.invalid}>
            {label}
        </Checkbox>
    );
};

const SwitchControl = ({ children, name, label, type, ...rest }) => {
    const { input, meta } = useField(name, {
        type: "checkbox" // important for RFF to manage the checked prop
    });
    return (
        <Flex justify="left" align="center">
            <Switch
                size="md"
                mr={2}
                isChecked={input.checked}
                {...input}
                isInvalid={meta.invalid}
            ></Switch>
            <FormLabel htmlFor={name}>{children}</FormLabel>
        </Flex>
    );
};

const ErrorMessage = ({ children }) => {
    return (
        <small
            className={css`
                color: ${colors.red[500]};
            `}
        >
            {children}
        </small>
    );
};

const SelectFormControl = ({ children, name, label, type, size, ...rest }) => {
    const { input, meta } = useField(name, {
        type: type
    });
    return (
        <FormControl {...rest}>
            {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
            <Select
                {...input}
                title={meta.submitError}
                isInvalid={meta.invalid}
                id={name}
                size={size}
            >
                {children}
            </Select>
            {meta.submitError && <ErrorMessage>{meta.submitError}</ErrorMessage>}
        </FormControl>
    );
};

const InputFormControl = ({ name, label, type, placeholder, size, ...rest }) => {
    const { input, meta } = useField(name, {
        type: type
    });
    return (
        <FormControl {...rest}>
            {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
            <Input
                {...input}
                title={meta.submitError}
                isInvalid={meta.invalid}
                id={name}
                placeholder={placeholder}
                size={size}
            />
            {meta.submitError && <ErrorMessage>{meta.submitError}</ErrorMessage>}
        </FormControl>
    );
};

const TextareaFormControl = ({ name, label, type, placeholder, size, ...rest }) => {
    const { input, meta } = useField(name, {
        type: type
    });
    return (
        <FormControl {...rest}>
            {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
            <Textarea
                {...input}
                title={meta.submitError}
                isInvalid={meta.invalid}
                id={name}
                placeholder={placeholder}
                size={size}
            />
            {meta.submitError && <ErrorMessage>{meta.submitError}</ErrorMessage>}
        </FormControl>
    );
};

const NumberInputFieldRef = React.forwardRef(({ onKeyDown, ...rest }, ref) => {
    return (
        <NumberInputField
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            textAlign="center"
            paddingLeft="2.5rem"
            paddingRight="2.5rem"
            height="3.25rem"
            focusInputOnChange={false}
            ref={ref}
            onKeyDown={onKeyDown}
            {...rest}
        />
    );
});

const UpDownControl = React.forwardRef(
    ({ label, value, min = 1, width = "150px", onChange, children, ...rest }, ref) => {
        const preventInvalidKeys = e => {
            var allowedChars = "0123456789";
            function contains(stringValue, charValue) {
                return stringValue.indexOf(charValue) > -1;
            }
            var invalidKey =
                (e.key.length === 1 && !contains(allowedChars, e.key)) ||
                (e.key === "." && contains(e.target.value, "."));

            if (invalidKey) {
                e.preventDefault();
            }
        };

        return (
            <Box {...rest}>
                {label && <FormLabel>{label}</FormLabel>}
                <Flex alignItems="center">
                    <NumberInput value={value} min={min} width={width} onChange={onChange}>
                        <InputLeftElement top="7px" left="4px">
                            <IconButton
                                size="md"
                                height="2.5rem"
                                aria-label="Minder"
                                icon={FiMinus}
                                left="2px"
                                as={NumberDecrementStepper}
                            />
                        </InputLeftElement>
                        <NumberInputFieldRef
                            ref={ref}
                            onFocus={e => e.target.select()}
                            onKeyDown={preventInvalidKeys}
                        />
                        <InputRightElement top="7px" right="4px">
                            <IconButton
                                size="md"
                                height="2.5rem"
                                aria-label="Meer"
                                right="2px"
                                icon={FiPlus}
                                as={NumberIncrementStepper}
                            />
                        </InputRightElement>
                    </NumberInput>
                    {children}
                </Flex>
            </Box>
        );
    }
);

export {
    InputFormControl,
    CheckboxControl,
    CheckboxGroupControl,
    SwitchControl,
    TextareaFormControl,
    UpDownControl,
    RadioGroupControl,
    SelectFormControl
};
