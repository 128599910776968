import React, { useEffect, useRef, useState } from "react";
import { css } from "emotion";
import { colors } from "../../custom-theme";
import { IconButton } from "@chakra-ui/core";
import { FiShoppingCart, FiBox } from "react-icons/fi";
import cl from "classnames";
import { formatCurrency } from "../../helpers/format";
import { useCart } from "../../common/current-visit";
import produce from "immer";
import { db } from "../../services/db";
import { useOnScreen } from "../../common/hooks";
import { Link, useLocation } from "react-router-dom";
import qs from "qs";

const ProductCardStyles = css`
    border: 1px solid ${colors.gray[200]};
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .img {
        flex-grow: 1;
        position: relative;
        display: block;
        background-color: white;

        button {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 1;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            object-fit: contain;
            width: 100%;
            height: 100%;
        }

        .img-placeholder {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 84px;
            height: 84px;
            color: ${colors.gray[300]};
        }
    }

    .body {
        position: relative;
        display: flex;
        padding: 10px;

        > div {
            flex-grow: 1;

            h2 {
                display: block;
                font-weight: 600;
                margin-right: 8px;
                line-height: 1.1;
            }

            span {
                display: block;
                font-size: 13px;
                color: ${colors.gray[400]};
                i {
                    font-style: normal;
                }
            }
        }
    }
`;

const ProductCard = ({ isOnOrder, product, showButton, style }) => {
    const [cart, setCart] = useCart();
    const [dataUrl, setDataUrl] = useState(null);
    const ref = useRef();
    const onScreen = useOnScreen(ref, "-50px");
    const mounted = useRef(false);

    useEffect(() => {
        const fetchImage = async () => {
            var reader = new FileReader();
            var record = await db.productImages.get(product.id);
            if (record) {
                var blob = record.image;
                reader.onload = e => {
                    setDataUrl(e.target.result);
                };

                reader.readAsDataURL(blob);
            }
        };

        if (onScreen && mounted.current) fetchImage();
    }, [onScreen]);

    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    const addToCart = e => {
        const next = produce(cart, draft => {
            var tmp = draft.items.find(x => x.product.id === product.id);
            if (tmp) tmp.count++;
            else draft.items.unshift({ product: product, count: 1 });
        });
        setCart(next);
    };

    return (
        <div
            className={ProductCardStyles}
            style={{ ...style, width: style.width - 12, height: style.height - 12 }}
            data-id={product.id}
        >
            <div className="img" ref={ref}>
                {showButton && (
                    <IconButton
                        aria-label="Toevoegen aan winkelmandje"
                        onClick={addToCart}
                        icon={FiShoppingCart}
                    />
                )}

                {!!dataUrl && <img src={dataUrl} alt={product.name} />}
                {!dataUrl && <FiBox className="img-placeholder" />}
            </div>
            <div className="body">
                <div>
                    <h2>{product.name}</h2>
                    <div>
                        {(!isOnOrder || !cart.active || !!cart.company.show_prices) && (
                            <span>
                                <i
                                    className={css`
                                        color: ${colors.gray[600]};
                                    `}
                                >
                                    {formatCurrency(product.price)}
                                </i>
                                {!!product.originalPrice && (
                                    <i
                                        className={css`
                                            text-decoration: line-through;
                                            margin-left: 20px;
                                        `}
                                    >
                                        {formatCurrency(product.originalPrice)}
                                    </i>
                                )}

                                {product.discountPercentage && (
                                    <i>({product.discountPercentage}%)</i>
                                )}
                                {!!product.pieces_per_pack && (
                                    <span>({product.pieces_per_pack} per pallet)</span>
                                )}
                            </span>
                        )}
                        <span>{product.code}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ProductGroupCard = ({ productgroup }) => {
    const location = useLocation();

    var s = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (s.group) s.subgroup = productgroup.id;
    else s.group = productgroup.id;

    return (
        <Link
            className={cl(
                ProductCardStyles,
                css`
                    &:hover {
                        background: ${colors.gray[50]};
                    }
                `
            )}
            to={qs.stringify(s, { addQueryPrefix: true })}
        >
            <div
                className={css`
                    padding: 12px 10px;
                `}
            >
                {productgroup.name}
            </div>
        </Link>
    );
};

export { ProductCard, ProductGroupCard };
