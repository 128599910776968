import { FormControl, FormLabel, Input, Select } from "@chakra-ui/core";
import React, { useEffect, useState } from "react";
import { useField } from "react-final-form";
import { db } from "../services/db";

const SelectCompanytype = ({ name, label, type, size, ...rest }) => {
    const [items, setItems] = useState([]);
    const { input, meta } = useField(name, {
        type: type
    });

    useEffect(() => {
        async function fetch() {
            var items = await db.companytypes.orderBy("name_upper").toArray();
            setItems(items);
        }

        fetch();
    }, []);

    return (
        <FormControl {...rest}>
            {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
            <Select
                {...input}
                title={meta.submitError}
                isInvalid={meta.invalid}
                id={name}
                placeholder="- Kies een bedrijfstype -"
                size={size}
            >
                {items.map(item => (
                    <option key={item.id} value={item.id}>
                        {item.name}
                    </option>
                ))}
            </Select>
        </FormControl>
    );
};
export { SelectCompanytype };
