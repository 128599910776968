import { format, parseISO } from "date-fns";
import { parse } from "date-fns/esm";

const formatPhoneHref = n => {
    if (!n) return n;
    return n.replace("(0)", "").replace(/ /g, "");
};

const formatCurrency = (n, decimals = 2) => {
    return new Intl.NumberFormat("be-NL", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    }).format(n);
};

const formatDate = d => {
    var tmp = d;
    if (typeof d === "string") {
        var tmp = parseISO(d);
    }
    return format(tmp, "dd/MM/yyyy", {
        code: "nl_BE"
    });
};

export { formatPhoneHref, formatCurrency, formatDate };
