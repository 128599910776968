import React from "react";
import styled from "@emotion/styled";
import { css } from "emotion";
import { Box } from "@chakra-ui/core";
import { Form } from "react-final-form";

const Panel = styled.div`
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
    margin-bottom: 20px;
    overflow: hidden;
`;

const SettingsPanelStyles = css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
    min-height: 0;
`;
const SettingsPanel = ({ title, actions, children, ...rest }) => {
    return (
        <div className={SettingsPanelStyles} {...rest}>
            <SettingsPanelHeader title={title} actions={actions} />
            <SettingsPanelBody>{children}</SettingsPanelBody>
        </div>
    );
};

const SettingsPanelForm = ({ title, actions, render, children, ...rest }) => {
    return (
        <Form
            {...rest}
            render={({ handleSubmit, submitting, dirty, pristine, values }) => (
                <form onSubmit={handleSubmit} className={SettingsPanelStyles}>
                    <SettingsPanelHeader title={title} actions={actions} />
                    <SettingsPanelBody>
                        {render({ handleSubmit, submitting, dirty, pristine, values })}
                    </SettingsPanelBody>
                </form>
            )}
        />
    );
};

const SettingsPanelHeader = ({ title, actions }) => {
    return (
        <div
            className={css`
                display: flex;
                align-items: center;
                padding: 20px;
                min-height: 72px;

                h2 {
                    font-size: 18px;
                    font-weight: 600;
                }
            `}
        >
            {title && <h2>{title}</h2>}
            {actions && <Box marginLeft="auto">{actions}</Box>}
        </div>
    );
};

const SettingsPanelBody = ({ children }) => {
    return (
        <div
            className={css`
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                min-height: 0;
                padding-bottom: 16px;

                &:only-child {
                    padding-top: 20px;
                }

                > .wrapper {
                    overflow-y: auto;
                    -webkit-overflow-scrolling: touch;
                    padding: 0 20px;
                    padding-bottom: 4px;
                    flex-grow: 1;
                }
            `}
        >
            <div className="wrapper">{children}</div>
        </div>
    );
};

const ButtonWrapper = styled.div`
    margin-bottom: 10px;
`;

export { Panel, SettingsPanel, SettingsPanelForm, ButtonWrapper };
