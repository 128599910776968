import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button, ButtonGroup, Stack, Spinner, Box, Flex, IconButton, Text } from "@chakra-ui/core";
import arrayMutators from "final-form-arrays";
import { useLocationChange } from "../../common/hooks";
import { FiCheck, FiPlus, FiTrash2 } from "react-icons/fi";
import { insertOrUpdateCompany } from "../../services/company-service";
import { TabContent, TabContentForm } from "../../components/tab-content";
import { InputFormControl, SwitchControl, TextareaFormControl } from "../../components/controls";
import { updateUserLocation } from "../../common/location";
import { SelectCompanytype } from "../../components/select";
import { isAdmin } from "../../common/user-info";
import { FieldArray } from "react-final-form-arrays";
import { css } from "emotion";
import Axios from "axios";

const ContactList = ({ fields, meta }) => {
    const handleAdd = e => {
        fields.push({});
    };

    const handleRemove = index => e => {
        fields.remove(index);
    };

    return (
        <>
            <Text
                className={css`
                    font-size: 14px;
                    font-weight: 600;
                `}
                as="h2"
                mt={4}
                mb={2}
            >
                Contactpersonen
            </Text>
            {fields.map((name, index) => (
                <Flex mt={3} key={index}>
                    <InputFormControl
                        placeholder="Naam"
                        flexGrow="1"
                        mr={3}
                        size="sm"
                        name={`${name}.name`}
                    />
                    <InputFormControl
                        placeholder="Telefoon"
                        flexGrow="1"
                        mr={3}
                        size="sm"
                        name={`${name}.phone`}
                    />
                    <InputFormControl
                        placeholder="Email"
                        type="email"
                        flexGrow="1"
                        mr={3}
                        size="sm"
                        name={`${name}.email`}
                    />
                    <IconButton
                        onClick={handleRemove(index)}
                        variantColor="red"
                        aria-label="Verwijderen"
                        size="sm"
                        icon={FiTrash2}
                    />
                </Flex>
            ))}
            <Box marginTop="20px">
                <Button type="button" onClick={handleAdd} leftIcon={FiPlus} size="sm">
                    Toevoegen
                </Button>
            </Box>
        </>
    );
};

const CompanyFicheEdit = () => {
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const history = useHistory();

    const match = useRouteMatch();

    useLocationChange(() => {
        async function fetch() {
            if (match.params.id === "new") {
                setCompany({
                    name: "",
                    is_active: true,
                    show_prices: true,
                    info: "",
                    line_discount: 0,
                    max_discount: 0,
                    vat_number: "",
                    address: "",
                    zipcode: "",
                    city: "",
                    email: "",
                    phone: "",
                });
            } else {
                var res = await Axios.get(`/api/company/${match.params.id}`);

                setCompany(res.data);
            }
            setLoading(false);
        }
        fetch();
    });

    const onSubmit = async values => {
        setIsSaving(true);
        //select type !!!!
        if (match.params.id === "new") {
            try {
                var res = await Axios.put("/api/company", values);
                await insertOrUpdateCompany(res.data);
                history.push(`/companies/${res.data.id}?tab=0`);
            } catch (error) {
                console.log("ERROR", error.response.data.errors);
                setIsSaving(false);
                return error.response.data.errors;
            }
        } else {
            try {
                var res = await Axios.post("/api/company/" + match.params.id, values);
                await insertOrUpdateCompany(res.data);
                history.push(`/companies/${res.data.id}?tab=0`);
            } catch (error) {
                console.log("ERROR", error.response.data.errors);
                setIsSaving(false);

                return error.response.data.errors;
            }
        }

        // fire and forget update user location
        updateUserLocation();
    };

    const onCancel = () => {
        if (match.params.id === "new") {
            history.push(`/companies?tab=0`);
        } else {
            history.push(`/companies/${match.params.id}?tab=0`);
        }
    };

    return (
        <>
            {loading && (
                <TabContent>
                    <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="red.500"
                        size="md"
                    />
                </TabContent>
            )}
            {!loading && (
                <TabContentForm
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    initialValues={company}
                    title="Fiche bewerken"
                    mutators={{
                        ...arrayMutators
                    }}
                    render={({ handleSubmit, submitting, dirty, pristine, values }) => (
                        <Stack spacing="2">
                            <InputFormControl label="Naam" name="name" />
                            <SelectCompanytype label="Type" name="companytype_id" />

                            <Stack spacing="2" isInline={true}>
                                <InputFormControl
                                    label="Straat + nr"
                                    name="address"
                                    width="50%"
                                    flexShrink="0"
                                />
                                <InputFormControl label="Postcode" name="zipcode" width="20%" />
                                <InputFormControl
                                    label="Gemeente"
                                    name="city"
                                    width="20%"
                                    width="30%"
                                />
                            </Stack>

                            <Stack spacing="2" isInline={true}>
                                <InputFormControl
                                    type="email"
                                    label="E-mail"
                                    name="email"
                                    width="50%"
                                    flexShrink="0"
                                />
                                <InputFormControl label="Telefoon" name="phone" width="50%" />
                            </Stack>

                            <InputFormControl label="BTW nummer" name="vat_number" />
                            {isAdmin() && (
                                <Stack spacing="2" isInline={true}>
                                    <InputFormControl
                                        label="Basiskorting %"
                                        name="line_discount"
                                        width="50%"
                                        flexShrink="0"
                                    />
                                    <InputFormControl
                                        label="Max. lijnkorting %"
                                        name="max_discount"
                                        width="50%"
                                    />
                                </Stack>
                            )}
                            <TextareaFormControl label="Info" name="info" />

                            {isAdmin() && (
                                <Stack mt={4}>
                                    <SwitchControl name="is_active">Actief</SwitchControl>
                                    <SwitchControl name="show_prices">Prijzen tonen</SwitchControl>
                                </Stack>
                            )}

                            <FieldArray name="contacts">
                                {({ fields }) => <ContactList fields={fields} />}
                            </FieldArray>
                        </Stack>
                    )}
                    buttonsRight={
                        <ButtonGroup size="sm" marginLeft="auto">
                            <Button isDisabled={isSaving} onClick={onCancel}>
                                Annuleren
                            </Button>
                            <Button
                                type="submit"
                                leftIcon={FiCheck}
                                isLoading={isSaving}
                                variantColor="green"
                                loadingText="Opslaan"
                            >
                                Opslaan
                            </Button>
                        </ButtonGroup>
                    }
                ></TabContentForm>
            )}
        </>
    );
};

export { CompanyFicheEdit };
