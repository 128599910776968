import React from "react";
import { ProductsSearch } from "../../components/order/order-products-search";
import { ProductsList } from "../../components/order/order-products-list";
import { Cart } from "../../components/order/order-cart";
import { Layout, LayoutSidebar, LayoutBody } from "../../components/layout";

const OrderPage = ({ isRemoteOrder }) => {
    return (
        <Layout title={isRemoteOrder ? "Remote bestelling" : "Bestelling bij bezoek"}>
            <LayoutSidebar>
                <Cart isRemoteOrder={isRemoteOrder} />
            </LayoutSidebar>
            <LayoutBody>
                <ProductsSearch isRemoteOrder={isRemoteOrder} showSort={true} showScanner={true} />
                <ProductsList isOnOrder={true} isRemoteOrder={isRemoteOrder} showButton={true} />
            </LayoutBody>
        </Layout>
    );
};

export { OrderPage };
