import React from "react";
import { css } from "emotion";
import { Form } from "react-final-form";

const TabContentHeaderStyles = css`
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 20px;
    min-height: 72px;

    h2 {
        font-size: 18px;
        font-weight: 600;
    }
    /* 
    > *:nth-child(2) {
        margin-left: auto;
    } */
`;

const TabContentHeader = ({ title, buttonsLeft, buttonsRight }) => {
    return (
        <div className={TabContentHeaderStyles}>
            {title && <h2>{title}</h2>}
            {buttonsLeft}
            {buttonsRight}
        </div>
    );
};

const TabContentBodyStyles = css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    padding-bottom: 20px;

    &:only-child {
        padding-top: 20px;
    }

    > .wrapper {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 0 20px;
    }
`;

const TabContentBody = ({ children }) => {
    return (
        <div className={TabContentBodyStyles}>
            <div className="wrapper">{children}</div>
        </div>
    );
};

const TabContentStyles = css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
`;

const TabContent = ({ children, title, buttonsLeft, buttonsRight, ...rest }) => {
    return (
        <div className={TabContentStyles} {...rest}>
            {(buttonsLeft || buttonsRight || title) && (
                <TabContentHeader
                    title={title}
                    buttonsLeft={buttonsLeft}
                    buttonsRight={buttonsRight}
                />
            )}
            <TabContentBody>{children}</TabContentBody>
        </div>
    );
};
const TabContentForm = ({ title, buttonsLeft, buttonsRight, render, ...rest }) => {
    return (
        <Form
            {...rest}
            render={({ handleSubmit, submitting, dirty, pristine, values }) => (
                <form onSubmit={handleSubmit} className={TabContentStyles}>
                    {(buttonsLeft || buttonsRight || title) && (
                        <TabContentHeader title={title} buttonsLeft={buttonsLeft} buttonsRight={buttonsRight} />
                    )}
                    <TabContentBody>
                        {render({ handleSubmit, submitting, dirty, pristine, values })}
                    </TabContentBody>
                </form>
            )}
        />
    );
};

export { TabContent, TabContentForm };
