import React, { useEffect, useState } from "react";
import { css } from "emotion";
import qs from "qs";
import {
    FormLabel,
    Switch,
    Stack,
    IconButton,
    Button,
    Input,
    InputGroup,
    InputRightElement,
    Box,
    Popover,
    Select,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    FormControl,
    ButtonGroup
} from "@chakra-ui/core";
import { FiSettings, FiSearch } from "react-icons/fi";
import { useLocalStorage } from "react-use";
import { MESSAGES } from "../../common/const";
import { useDebounceEffect, useLocationChange } from "../../common/hooks";
import { useHistory, useLocation } from "react-router-dom";

const CompanySearchStyles = css`
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;

const CompanySearch = ({ ...rest }) => {
    const history = useHistory();
    const location = useLocation();
    const [sort, setSort] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState(() => {
        var q = qs.parse(location.search, { ignoreQueryPrefix: true });
        return q.s || "";
    });
    useLocationChange(location => {
        var q = qs.parse(location.search, { ignoreQueryPrefix: true });
        var tmp = q.s || "";
        setSort(q.sort);
        setSearch(tmp);
    });
    const debouncedSearch = useDebounceEffect(search, 400);

    const handleChangeSearch = e => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        var q = qs.parse(location.search, { ignoreQueryPrefix: true });
        q.s = search;
        history.push("?" + qs.stringify(q));
    }, [debouncedSearch]);

    const [columnSettings, setColumnSettings] = useLocalStorage("column_settings", {
        showCity: false,
        showType: false,
        showPhone: false
    });

    const [showCity, setShowCity] = useState(columnSettings.showCity);
    const [showType, setShowType] = useState(columnSettings.showType);
    const [showPhone, setShowPhone] = useState(columnSettings.showPhone);

    const handleChangeCity = e => {
        setShowCity(e.target.checked);
    };
    const handleChangeType = e => {
        setShowType(e.target.checked);
    };
    const handleChangePhone = e => {
        setShowPhone(e.target.checked);
    };

    const handleApply = e => {
        var tmp = {
            showCity: showCity,
            showType: showType,
            showPhone: showPhone
        };
        setColumnSettings(tmp);
        PubSub.publish(MESSAGES.ColumnSettings, tmp);
        close();
    };
    const handleCancel = e => {
        setShowCity(columnSettings.showCity);
        setShowType(columnSettings.showType);
        setShowPhone(columnSettings.showPhone);
        close();
    };

    const handleChangeSort = e => {
        var q = qs.parse(location.search, { ignoreQueryPrefix: true });
        q.sort = e.target.value;
        history.push("?" + qs.stringify(q));
    };

    const open = () => setIsOpen(!isOpen);
    const close = () => setIsOpen(false);

    return (
        <div className={CompanySearchStyles} {...rest}>
            <Stack spacing="2">
                <Stack spacing="2" isInline>
                    <Select
                        value={sort}
                        onChange={handleChangeSort}
                        rounded="5px"
                        placeholder="Sorteren op..."
                        iconSize="24px"
                    >
                        <option value="name_asc">Naam oplopend</option>
                        <option value="name_desc">Naam aflopend</option>
                        <option value="city_asc">Gemeente oplopend</option>
                        <option value="city_desc">Gemeente aflopend</option>
                        <option value="last_visit_asc">Laatste bezoek oplopend</option>
                        <option value="last_visit_desc">Laatste bezoek aflopend</option>
                    </Select>
                    <Popover returnFocusOnClose={false} isOpen={isOpen} onClose={close}>
                        <PopoverTrigger>
                            <IconButton
                                onClick={open}
                                aria-label="Settings"
                                icon={FiSettings}
                                fontSize="20px"
                            />
                        </PopoverTrigger>
                        <PopoverContent zIndex={4}>
                            <PopoverHeader fontWeight="semibold">Instellingen</PopoverHeader>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                                <Stack spacing="2">
                                    <FormControl alignItems="center">
                                        <Switch
                                            isChecked={showCity}
                                            onChange={handleChangeCity}
                                            marginRight="8px"
                                            color="green"
                                        />
                                        <FormLabel htmlFor="show-location">Toon gemeente</FormLabel>
                                    </FormControl>
                                    <FormControl alignItems="center">
                                        <Switch
                                            isChecked={showPhone}
                                            onChange={handleChangePhone}
                                            marginRight="8px"
                                            color="green"
                                        />
                                        <FormLabel htmlFor="show-location">Toon telefoon</FormLabel>
                                    </FormControl>
                                    <FormControl alignItems="center">
                                        <Switch
                                            isChecked={showType}
                                            onChange={handleChangeType}
                                            marginRight="8px"
                                            color="green"
                                        />
                                        <FormLabel htmlFor="show-company-type">Toon type</FormLabel>
                                    </FormControl>
                                </Stack>
                            </PopoverBody>

                            <PopoverFooter d="flex" justifyContent="flex-end">
                                <ButtonGroup size="sm">
                                    <Button onClick={handleCancel} variant="outline">
                                        Annuleren
                                    </Button>
                                    <Button onClick={handleApply}>Toepassen</Button>
                                </ButtonGroup>
                            </PopoverFooter>
                        </PopoverContent>
                    </Popover>
                </Stack>
                <InputGroup flexGrow="1">
                    <InputRightElement
                        style={{ pointerEvents: "none" }}
                        children={<Box as={FiSearch} size="20px" />}
                    />
                    <Input
                        variant="filled"
                        rounded="5px"
                        type="text"
                        onChange={handleChangeSearch}
                        value={search}
                        placeholder="Zoek klant/prospect"
                    />
                </InputGroup>
            </Stack>
        </div>
    );
};

export { CompanySearch };
