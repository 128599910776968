import React from "react";
import { Layout, LayoutBody } from "../../components/layout";
import { ProductsSearch } from "../../components/order/order-products-search";
import { ProductsList } from "../../components/order/order-products-list";

const ProductsPage = () => {
    return (
        <Layout title="Producten">
            <LayoutBody>
                <ProductsSearch showSort={false} showScanner={false} />
                <ProductsList isOnOrder={false} showButton={false} colCount={4} />
            </LayoutBody>
        </Layout>
    );
};

export { ProductsPage };
