import React, { useState, useEffect } from "react";
import { css } from "emotion";
import { NavLink, useLocation } from "react-router-dom";
import { FiUserCheck, FiUser } from "react-icons/fi";
import cl from "classnames";
import { colors } from "../../custom-theme";
import { useLocalStorage } from "react-use";
import { getUserInfo, isAdmin } from "../../common/user-info";
import { MESSAGES } from "../../common/const";

const CompanyListItemStyles = height => css`
    padding: 10px 20px;
    border-bottom: 1px solid ${colors.gray[200]};
    transition: background-color 0.2s ease;
    display: flex;
    height: ${height + "px"};
    .wrapper {
        display: flex;
    }
    .icons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 10px;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        font-size: 18px;
        font-weight: 600;
        border-radius: 100%;
        color: ${colors.gray[600]};
        transform: translateY(4px);
        transition: color 0.2s ease, background-color 0.2s ease;

        &--client {
            svg {
                transform: translateX(1px);
            }
        }
    }

    .time {
        align-self: center;
        display: block;
        background-color: ${colors.green[500]};
        width: 8px;
        height: 8px;
        border-radius: 100%;
        transform: translateY(-6px);

        &--longVisit {
            background-color: ${colors.red[500]};
        }
    }

    .body {
        h2 {
            display: block;
            font-weight: 600;
            transition: color 0.2s ease;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 220px;
            user-select: none;
        }

        span {
            display: block;
            user-select: none;
            font-size: 13px;
            color: ${colors.gray[400]};
            transition: color 0.2s ease;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 220px;
        }
    }

    &.active {
        background-color: ${colors.gray[600]};

        .icon {
            color: white;
        }

        .body {
            span,
            h2 {
                color: white;
            }
        }
    }
`;

const CompanyListItem = ({ height, reactWindowStyle, company, id }) => {
    const [savedColumnSettings] = useLocalStorage("column_settings");
    const [columnSettings, setColumnSetttings] = useState(savedColumnSettings);
    const location = useLocation();

    useEffect(() => {
        var token = PubSub.subscribe(MESSAGES.ColumnSettings, (msg, data) => {
            setColumnSetttings(data);
        });

        return () => {
            // unmount
            PubSub.unsubscribe(token);
        };
    }, []);

    var secondLine = [];
    if (company) {
        if (columnSettings.showCity) secondLine.push(company.city);
        if (columnSettings.showType && company.companytype)
            secondLine.push(company.companytype.name);
        if (columnSettings.showPhone) secondLine.push(company.phone);
    }
    const secondLineText = company ? secondLine.join(" - ") : "";

    return (
        <NavLink
            to={"/companies/" + id + location.search}
            className={CompanyListItemStyles(height)}
        >
            {!company && <div style={reactWindowStyle}>Loading</div>}
            {!!company && (
                <div
                    title={company.name}
                    className={cl("wrapper", {
                        [css`
                            opacity: 0.4;
                        `]:
                            !company.is_active ||
                            (company.salesrep_users_id !== getUserInfo().id && !isAdmin())
                    })}
                    style={reactWindowStyle}
                >
                    <>
                        <div className="icons">
                            <div className={"icon"}>
                                {company.record_type == "customer" ? <FiUserCheck /> : <FiUser />}
                            </div>
                            <div
                                className={[
                                    "time",
                                    company.longVisit ? "time--longVisit" : ""
                                ].join(" ")}
                            ></div>
                        </div>
                        <div className="body">
                            <h2>{company.name}</h2>
                            <span>{secondLineText || "\u00A0"}</span>
                        </div>
                    </>
                </div>
            )}
        </NavLink>
    );
};

export { CompanyListItem };
