import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { SettingsPanelForm } from "../../components/panel";

import { ButtonGroup, Button, Stack, useToast } from "@chakra-ui/core";
import { FiCheck } from "react-icons/fi";
import { InputFormControl, SelectFormControl, SwitchControl } from "../../components/controls";
import { useLocationChange } from "../../common/hooks";
import Axios from "axios";

const UserEdit = () => {
    const [user, setUser] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const toast = useToast();

    const match = useRouteMatch();

    useLocationChange(() => {
        async function fetch() {
            if (match.params.id === "new") {
                setUser({
                    name: "",
                    is_active: true,
                    email: ""
                });
            } else {
                var res = await Axios.get(`/api/user/${match.params.id}`);

                setUser(res.data);
            }
            setLoading(false);
        }
        fetch();
    });

    const onCancel = () => {
        console.log("Cancel");
        history.push(`/settings/users`);
    };

    const showToast = () => {
        toast({
            title: "Gebruiker",
            description: "Gebruiker opgeslagen.",
            status: "success",
            isClosable: true
        });
    };

    const onSubmit = async values => {
        setIsSaving(true);
        //select type !!!!
        if (match.params.id === "new") {
            try {
                var res = await Axios.put("/api/user", values);
                showToast();
                history.push(`/settings/users`);
            } catch (error) {
                console.log("ERROR", error.response.data.errors);
                setIsSaving(false);

                return error.response.data.errors;
            }
        } else {
            try {
                var res = await Axios.post("/api/user/" + match.params.id, values);
                showToast();
                history.push(`/settings/users`);
            } catch (error) {
                console.log("ERROR", error.response.data.errors);
                setIsSaving(false);

                return error.response.data.errors;
            }
        }
    };

    return (
        <>
            <SettingsPanelForm
                title="Gebruiker toevoegen"
                onSubmit={onSubmit}
                onCancel={onCancel}
                initialValues={user}
                actions={
                    <ButtonGroup size="sm">
                        <Button isDisabled={isSaving} onClick={onCancel}>
                            Annuleren
                        </Button>
                        <Button
                            isLoading={isSaving}
                            type="submit"
                            leftIcon={FiCheck}
                            variantColor="green"
                        >
                            Opslaan
                        </Button>
                    </ButtonGroup>
                }
                render={({ handleSubmit, submitting, dirty, pristine, values }) => (
                    <Stack spacing="2">
                        <InputFormControl label="Naam" name="name" />
                        <InputFormControl label="E-mail" name="email" />
                        <SelectFormControl label="Groep" name="roles">
                            <option value="admin">Admins</option>
                            <option value="internal">Interne dienst</option>
                            <option value="">Sales representatives</option>
                        </SelectFormControl>
                        <Stack mt={4}>
                            <SwitchControl name="is_active">Actief</SwitchControl>
                            <SwitchControl name="reset_password">Wachtwoord resetten</SwitchControl>
                        </Stack>
                    </Stack>
                )}
            />
        </>
    );
};

export { UserEdit };
