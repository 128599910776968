import React, { useRef, useState } from "react";
import { css } from "emotion";
import { useLocationChange } from "../../common/hooks";
import {
    Spinner,
    Link,
    Button,
    IconButton,
    useToast,
    Stack,
    Input,
    Box,
    useDisclosure,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    FormControl,
    FormLabel,
    Textarea
} from "@chakra-ui/core";
import { NavLink, Prompt, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { FiArrowLeft, FiTrash2, FiBox, FiCheck, FiMessageSquare } from "react-icons/fi";
import { UpDownControl } from "../controls";
import { defaultCart, useCart, useCurrentVisit } from "../../common/current-visit";
import {
    CartBodyStyles,
    CartHeaderStyles,
    CartFooterStyles,
    CartItemStyles,
    CartStyles
} from "./cart-styles";
import { formatCurrency } from "../../helpers/format";
import produce from "immer";
import { calculateCart } from "../../services/cart-service";
import { useSetRecoilState } from "recoil";
import { reportModalState } from "../../atoms";
import { db } from "../../services/db";
import { deepCopy } from "../../common/utils";
import { getCompany } from "../../services/company-service";
import { useMemo } from "react";
import { useEffect } from "react";
import { colors } from "../../custom-theme";
import { formatISO } from "date-fns";

const CartHeader = ({ to, text }) => {
    return (
        <div className={CartHeaderStyles}>
            <Link as={NavLink} to={to} d="flex" alignItems="center">
                <FiArrowLeft />
                {text}
            </Link>
        </div>
    );
};

const CartBody = () => {
    const [cart] = useCart();

    return (
        <div className={CartBodyStyles}>
            {cart.items.map(item => (
                <CartItem key={item.product.id} item={item} />
            ))}
        </div>
    );
};

const Stock = ({ item }) => {
    var color = colors.gray[200];
    var x;
    if (
        item.product.safety_factor != null &&
        item.product.supplier_bonus != null &&
        item.product.average_sale_week &&
        item.product.stock != null
    ) {
        x =
            (item.product.stock + item.product.supplier_bonus - item.count) /
            item.product.average_sale_week;

        if (x >= item.product.safety_factor) {
            color = colors.green[400];
        } else if (x > 0) {
            color = colors.orange[300];
        } else {
            color = colors.red[400];
        }
    }

    return (
        <div
            title={"X: " + x}
            style={{ background: color }}
            className={css`
                border: 2px solid white;
                position: absolute;
                width: 14px;
                height: 14px;
                top: 7px;
                left: 62px;
                border-radius: 50%;
            `}
        ></div>
    );
};

const CartFooter = ({ isRemoteOrder, onOrder }) => {
    const [currentVisit] = useCurrentVisit();
    const [remark, setRemark] = useState("");
    const [reference, setReference] = useState("");
    const { isOpen: isRemarkOpen, onOpen: onRemarkOpen, onClose: onRemarkClose } = useDisclosure();
    const [cart, setCart] = useCart();
    const firstFieldRef = useRef();
    const setIsOpenVisitorReport = useSetRecoilState(reportModalState);

    var calculation = useMemo(() => calculateCart(cart), [cart]);

    useEffect(() => {
        if (isRemarkOpen) {
            setRemark(cart.remark);
            setReference(cart.reference);
        }
    }, [isRemarkOpen]);

    const handleStopVisit = e => {
        setIsOpenVisitorReport(true);
    };

    const handleSubmitRemark = () => {
        var next = produce(cart, draft => {
            draft.remark = remark;
            draft.reference = reference;
        });
        setCart(next);
        onRemarkClose();
    };

    return (
        <div className={CartFooterStyles}>
            <div
                className={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <div
                    className={css`
                        margin-right: 10px;
                    `}
                >
                    <Button variant="ghost" title="Opmerkingen" onClick={e => onRemarkOpen()}>
                        <FiMessageSquare
                            className={css`
                                width: 20px;
                                height: 20px;
                            `}
                        ></FiMessageSquare>
                        {(!!cart.remark || !!cart.reference) && (
                            <span
                                className={css`
                                    background: red;
                                    width: 10px;
                                    height: 10px;
                                    display: block;
                                    position: absolute;
                                    border-radius: 50%;
                                    right: 6px;
                                    top: 4px;
                                `}
                            ></span>
                        )}
                    </Button>
                </div>
                {!!cart.company.show_prices && (
                    <div
                        className={css`
                            flex: 1;
                        `}
                    >
                        <div className="row">
                            <span>Subtotaal</span>
                            <span>{formatCurrency(calculation.subtotal)}</span>
                        </div>

                        {!!calculation.lineDiscount && (
                            <div className="row">
                                <span>Extra lijnkorting</span>
                                <span>{formatCurrency(calculation.lineDiscount)}</span>
                            </div>
                        )}

                        <div className="row-total">
                            <span>Totaal</span>
                            <span>{formatCurrency(calculation.total)}</span>
                        </div>
                    </div>
                )}
            </div>
            <Button
                isDisabled={!cart.items.length || currentVisit.has_ordered}
                variantColor="green"
                width="100%"
                onClick={onOrder}
            >
                {!!currentVisit.has_ordered ? "Bestelling opgeslagen" : "Bestelling plaatsen"}
            </Button>
            {!isRemoteOrder && (
                <Button width="100%" mt={2} onClick={handleStopVisit}>
                    Bezoek stoppen
                </Button>
            )}
            <Drawer
                isOpen={isRemarkOpen}
                placement="right"
                onClose={onRemarkClose}
                initialFocusRef={firstFieldRef}
                size="md"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Opmerking / referentie</DrawerHeader>

                    <DrawerBody>
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Opmerkingen</FormLabel>
                                <Textarea
                                    value={reference}
                                    onChange={e => setReference(e.target.value)}
                                    height={300}
                                    ref={firstFieldRef}
                                ></Textarea>
                            </FormControl>

                            <Stack direction="row" mt="8">
                                <Button
                                    variantColor="green"
                                    leftIcon={FiCheck}
                                    onClick={handleSubmitRemark}
                                >
                                    Toepassen
                                </Button>
                            </Stack>
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </div>
    );
};

const CartItem = ({ item }) => {
    const [cart, setCart] = useCart();
    const firstFieldRef = useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [discount, setDiscount] = useState(0);
    const [amount, setAmount] = useState(0);
    const [dataUrl, setDataUrl] = useState(null);

    useEffect(() => {
        const fetch = async () => {
            var reader = new FileReader();
            var record = await db.productImages.get(item.product.id);
            if (record) {
                var blob = record.image;
                reader.onload = e => {
                    setDataUrl(e.target.result);
                };
                reader.readAsDataURL(blob);
            }
        };

        fetch();
        setAmount(item.count);
        setDiscount(item.discount || 0);
    }, [item]);

    const handleAmountChange = amount => {
        setAmount(amount);
    };

    const handleInlineAmountChange = item => value => {
        var next = produce(cart, draft => {
            var tmp = draft.items.find(x => x.product.id === item.product.id);
            tmp.count = value;
        });
        setCart(next);
    };

    const handleDiscountChange = e => {
        setDiscount(e.target.value);
    };

    const handleDelete = e => {
        var next = produce(cart, draft => {
            var i = draft.items.indexOf(draft.items.find(x => x.product.id === item.product.id));
            draft.items.splice(i, 1);
        });
        setCart(next);

        onClose();
    };

    const handleSubmitDrawer = e => {
        var next = produce(cart, draft => {
            var tmp = draft.items.find(x => x.product.id === item.product.id);

            //discount
            if (discount != "") tmp.discount = parseFloat(discount);
            else tmp.discount = "";

            //count
            tmp.count = amount;
        });
        setCart(next);

        onClose();
    };

    return (
        <>
            <Box className={CartItemStyles}>
                <button className="img" onClick={onOpen} type="button">
                    <div className="img-aspect">
                        {!!dataUrl && <img src={dataUrl} alt={item.product.name} />}
                        {!dataUrl && <FiBox className="img-placeholder" />}
                    </div>
                </button>
                <div className="body">
                    <h2>{item.product.name}</h2>
                    <span>{item.product.code}</span>
                    <span>
                        {formatCurrency(item.product.price)}
                        {!!item.product.originalPrice && (
                            <>
                                <i
                                    className={css`
                                        text-decoration: line-through;
                                        font-style: normal;
                                        margin-left: 20px;
                                    `}
                                >
                                    {formatCurrency(item.product.originalPrice)}
                                </i>
                                {!!item.product.discountPercentage && (
                                    <i
                                        className={css`
                                            font-style: normal;
                                        `}
                                    ></i>
                                )}
                            </>
                        )}
                    </span>
                    <span>Korting: {item.discount || 0}%</span>
                    <UpDownControl
                        className={css`
                            position: absolute;
                            right: 0;
                            bottom: 0;
                        `}
                        mt={4}
                        value={item.count}
                        onChange={handleInlineAmountChange(item)}
                        ref={firstFieldRef}
                    />
                </div>
                <Stock item={item}></Stock>
            </Box>

            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                initialFocusRef={firstFieldRef}
                size="sm"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>{item.product.name}</DrawerHeader>

                    <DrawerBody>
                        <Stack spacing={2}>
                            <Box color={colors.gray[400]} fontSize="0.9rem">
                                <div>
                                    <span
                                        className={css`
                                            color: ${colors.gray[600]};
                                            font-weight: bold;
                                        `}
                                    >
                                        {formatCurrency(item.product.price)}
                                    </span>
                                    {!!item.product.originalPrice && (
                                        <span
                                            className={css`
                                                text-decoration: line-through;
                                                margin-left: 20px;
                                            `}
                                        >
                                            {formatCurrency(item.product.originalPrice)}
                                        </span>
                                    )}
                                    {item.product.discountPercentage && (
                                        <span>({item.product.discountPercentage}%)</span>
                                    )}
                                    {item.product.pieces_per_pack > 0 && (
                                        <span>(per {item.product.pieces_per_pack} stuks)</span>
                                    )}
                                </div>
                                <div>{item.product.barcode}</div>
                            </Box>

                            <UpDownControl
                                mt={4}
                                label="Aantal"
                                value={amount}
                                onChange={handleAmountChange}
                                width="100%"
                                ref={firstFieldRef}
                            />
                            <FormControl>
                                <FormLabel htmlFor="discount">Extra lijnkorting %</FormLabel>
                                <Input
                                    label="Korting %"
                                    value={discount}
                                    name="discount"
                                    type="number"
                                    min={0}
                                    max={99}
                                    pattern="[0-9]{2}"
                                    inputmode="numeric"
                                    title="Non-negative integral number"
                                    className={css`
                                        text-align: center;
                                    `}
                                    onChange={handleDiscountChange}
                                    width="100%"
                                />
                            </FormControl>
                            <Stack direction="row" mt="8">
                                <Button
                                    variantColor="green"
                                    leftIcon={FiCheck}
                                    onClick={handleSubmitDrawer}
                                >
                                    Toepassen
                                </Button>
                                <IconButton
                                    alignSelf="flex-end"
                                    variantColor="red"
                                    icon={FiTrash2}
                                    type="button"
                                    mr={2}
                                    onClick={handleDelete}
                                    size="md"
                                    ariaLabel="Verwijderen"
                                />
                            </Stack>
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

const Cart = ({ isRemoteOrder }) => {
    const [loading, setLoading] = useState(true);
    const [currentVisit, setCurrentVisit] = useCurrentVisit();
    const [cart, setCart] = useCart();
    const toast = useToast();
    const history = useHistory();
    const match = useRouteMatch();
    const currentLocation = useLocation();

    useLocationChange((newLocation, prevLocation) => {
        console.log("location change", newLocation);
        async function fetch() {
            if (isRemoteOrder) {
                if (match.params.id === "new" && newLocation?.pathname !== prevLocation?.pathname) {
                    var newCart = deepCopy(defaultCart);
                    var company = await getCompany(match.params.company);
                    console.log("company", company, match.params);
                    newCart.active = true;
                    newCart.company = company;
                    setCart(newCart);
                }
            }
            setLoading(false);
        }
        fetch();
    });

    useEffect(() => {
        return () => {
            if (isRemoteOrder) {
                console.log("Remote order unmounting, clearing cart.");
                setCart(null);
            }
        };
    }, []);

    const handleOrder = async e => {
        if (window.confirm("Bent u zeker dat u de bestelling wil voltooien?")) {
            var dto = deepCopy(cart);
            // calculate the totals and place them on the cart
            var calculation = calculateCart(cart);
            dto.subtotal = calculation.subtotal;
            dto.total = calculation.total;
            dto.discount = calculation.discount;
            dto.date = formatISO(new Date());

            if (isRemoteOrder) {
                var orderId = await db.newOrders.add(dto);
                var companyId = cart.company.id;
                setCart(deepCopy(defaultCart));
                setTimeout(() => {
                    // on next tick, so the new cart state is used for the prompt
                    history.push(`/companies/${companyId}`);
                });
            } else {
                var orderId = await db.newOrders.add(dto);

                const next = produce(currentVisit, draft => {
                    draft.has_ordered = true;
                });
                setCurrentVisit(next);
            }

            toast({
                title: "bestelling",
                description: "Bestelling bewaard.",
                status: "success",
                isClosable: true
            });
        }
    };

    const allowRedirect = (newLocation, active) => {
        if (
            !!isRemoteOrder &&
            cart &&
            cart.active &&
            newLocation.pathname != currentLocation.pathname
        )
            return "De bestelling is niet opgeslagen. Ben je zeker?";

        return true;
    };

    if (!cart || !cart.company) return <div className={CartStyles}></div>;

    return (
        <div className={CartStyles}>
            <>
                <Prompt message={allowRedirect} />
                {loading && (
                    <div
                        className={css`
                            padding: "20px";
                        `}
                    >
                        <Spinner
                            thickness="2px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="red.500"
                            size="md"
                        />
                    </div>
                )}
                {!loading && (
                    <>
                        <CartHeader
                            isRemoteOrder={isRemoteOrder}
                            to={`/companies/${cart.company.id}`}
                            text={cart.company.name}
                        />
                        <CartBody isRemoteOrder={isRemoteOrder} />
                        <CartFooter onOrder={handleOrder} isRemoteOrder={isRemoteOrder} />
                    </>
                )}
            </>
        </div>
    );
};

export { Cart };
