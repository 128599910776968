import { db } from "./db";
import { SYNCSTATUS } from "../common/const";
import voca from "voca";

async function queryCompanies(search, sort) {
    var sortValue = "name_upper";
    var reverse = false;
    if ((sort || "").indexOf("_desc") >= 0) reverse = true;

    switch (sort) {
        case "city_asc":
        case "city_desc":
            sortValue = "city_upper";
            break;
        case "last_visit_asc":
        case "last_visit_desc":
            sortValue = "last_visit";
            break;
    }
    /** @type import("dexie").Table */
    var query = db.companies;

    var baseQuery = search
        ? query
              .where("name_search")
              .startsWithIgnoreCase(search)
              .distinct()
        : query.orderBy(sortValue);

    if (reverse) baseQuery = baseQuery.reverse();

    return baseQuery;
}

async function countCompanies(search) {
    var baseQuery = await queryCompanies(search);
    return await baseQuery.count();
}

async function listCompanyKeys(search, sort) {
    var q = await queryCompanies(search, sort);

    return await q.primaryKeys();
}

async function listCompanies(search, sort) {
    var baseQuery = await queryCompanies(search, sort);

    console.log(baseQuery);
    if (search) {
        baseQuery = baseQuery.sortBy(sortValue);
    } else {
        baseQuery = baseQuery.toArray();
    }
    var items = await baseQuery;
    return items;
}

async function getCompany(id) {
    var tmp = await db.companies.get(parseInt(id));
    return tmp;
}

async function getChangedCompanies() {
    var localItems = await db.companies
        .where("sync_status")
        .notEqual(SYNCSTATUS.Synced)
        .toArray();

    return localItems;
}

async function insertOrUpdateCompany(dto) {
    var data = {
        ...dto,
        sync_status: SYNCSTATUS.New,
        name_upper: dto.name.toUpperCase(),
        city_upper: dto.city.toUpperCase(),
        last_visit: ""
    };
    data.name_search = voca.words(data.name_upper);
    await db.companies.put(data);
}

async function deleteCompany(id) {
    await db.companies.delete(parseInt(id));
}

export {
    listCompanies,
    getChangedCompanies,
    insertOrUpdateCompany,
    getCompany,
    deleteCompany,
    countCompanies,
    listCompanyKeys,
    queryCompanies
};
