const calculateCart = cart => {
    var subtotal = 0;
    var subtotalWithDiscount = 0;
    var totalDiscount = 0;

    for (let item of cart.items) {
        var price = item.count * item.product.price;
        var lineDiscountPct = item.discount || 0;

        // max line discount per customer
        if (lineDiscountPct > cart.company.max_discount && cart.company.max_discount > 0) {
            lineDiscountPct = cart.company.max_discount;
        }

        var lineDiscount = (price * lineDiscountPct) / 100;
        totalDiscount += lineDiscount;
        subtotal += price;
        subtotalWithDiscount += price - lineDiscount;
    }

    // totals
    var total = subtotalWithDiscount;

    return {
        total: total,
        subtotal: subtotal,
        discount: -totalDiscount,
        lineDiscount: lineDiscount
    };
};

export { calculateCart };
