import React, { useState } from "react";
import { css } from "emotion";
import qs from "qs";
import { VariableSizeGrid } from "react-window";
import { ProductCard, ProductGroupCard } from "../product/product-card";
import { useLocationChange, useQueryParams } from "../../common/hooks";
import { listGroups, listProducts } from "../../services/product-service";
import { useEffect } from "react";
import { Spinner } from "@chakra-ui/core";
import PubSub from "pubsub-js";
import { MESSAGES } from "../../common/const";
import { FiArrowLeft } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { useLocation, useMeasure } from "react-use";
import { useCart } from "../../common/current-visit";

const ProductsList = ({ isOnOrder, isRemoteOrder, showButton, colCount = 3 }) => {
    const [items, setItems] = useState(null);
    const [groups, setGroups] = useState(null);
    const [previousGroup, setPreviousGroup] = useState(null);
    const [loading, setLoading] = useState(false);
    const [measureRef, { x, y, width, height, top, right, bottom, left }] = useMeasure();
    const [cart] = useCart();
    const q = useQueryParams();
    const location = useLocation();
    const history = useHistory();

    async function fetch() {
        setLoading(true);

        if ((q.group && q.subgroup) || q.s) {
            // ready to show products
            var companyId = !!cart && !!cart.company && cart.company.id;
            var companyLineDiscount = !!cart && !!cart.company && cart.company.line_discount;
            var tmp = await listProducts(q.group, q.subgroup, q.s, companyId, companyLineDiscount);
            setItems(tmp);
            setGroups(null);
            setPreviousGroup({ id: q.group, text: "Terug" });
        } else {
            var tmp = await listGroups(q.group);
            setGroups(tmp);
            setItems(null);
            if (q.group) {
                setPreviousGroup({ id: null, text: "Terug" });
            } else {
                setPreviousGroup(null);
            }
        }
        setLoading(false);
    }

    useLocationChange(location => {
        fetch();
    });

    useEffect(() => {
        var token = PubSub.subscribe(MESSAGES.Sync, (msg, data) => {
            fetch();
            var q = qs.parse(location.search, { ignoreQueryPrefix: true });
            if (q.s) delete q.s;
            history.push("?" + qs.stringify(q));
        });

        return () => {
            // unmount
            PubSub.unsubscribe(token);
        };
    }, []);

    const Cell = ({ columnIndex, rowIndex, style }) => {
        var idx = rowIndex * 3 + columnIndex;
        var item = items[idx];

        if (!item) return null;

        return (
            <ProductCard
                key={item.id}
                style={style}
                isOnOrder={isOnOrder}
                product={item}
                showButton={showButton}
            />
        );
    };

    return (
        <>
            {loading && (
                <Spinner
                    thickness="2px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="red.500"
                    size="md"
                />
            )}
            {!loading && (
                <>
                    {previousGroup && (
                        <div
                            className={css`
                                grid-column: 1 / span 3;
                                margin-bottom: 10px;
                                a {
                                    align-items: center;
                                    display: inline-flex;
                                    padding: 10px 20px 10px 0;
                                    svg {
                                        margin-right: 10px;
                                    }
                                    :hover {
                                        text-decoration: underline;
                                    }
                                }
                            `}
                        >
                            <Link to={previousGroup.id ? "?group=" + previousGroup.id : "?"}>
                                <FiArrowLeft /> {previousGroup.text}
                            </Link>
                        </div>
                    )}
                    <div
                        className={css`
                            position: relative;
                            flex: 1;
                            overflow-y: auto;
                            display: flex;
                            flex-direction: column;

                            -webkit-overflow-scrolling: touch;
                            /* flex-grow: 1; */
                            min-height: 0;
                        `}
                    >
                        {!!groups && (
                            <div
                                className={css`
                                    display: grid;
                                    grid-template-columns: 1fr 1fr 1fr;
                                    gap: 12px;
                                    width: 100%;
                                `}
                            >
                                {groups.map(x => (
                                    <ProductGroupCard key={x.id} productgroup={x} />
                                ))}
                            </div>
                        )}
                        <div
                            ref={measureRef}
                            className={css`
                                flex-grow: 1;
                            `}
                        >
                            {!!items && !!width && (
                                <VariableSizeGrid
                                    columnCount={colCount}
                                    columnWidth={index => (width - 20) / colCount}
                                    height={height}
                                    rowCount={
                                        !!items.length ? Math.ceil(items.length / colCount) : 0
                                    }
                                    rowHeight={index => 250}
                                    width={width}
                                >
                                    {Cell}
                                </VariableSizeGrid>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export { ProductsList };
