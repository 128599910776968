import Axios from "axios";
import { db } from "../services/db";
import * as Sentry from "@sentry/react";

function getCurrentLocation(geoOptions) {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            position => {
                resolve(position);
            },
            error => {
                reject(error);
            },
            geoOptions
        );
        navigator.geolocation.getCurrentPosition(resolve, reject, geoOptions);
    });
}

async function updateUserLocation() {
    try {
        var position = await getCurrentLocation();
        var dto = {
            longitude: position.coords.longitude,
            latitude: position.coords.latitude
        };
        if (window.navigator.onLine) {
            console.log("Update user location while online", position.coords);
            var res = await Axios.post(`/api/user/update-location`, dto);
        } else {
            console.log("Update user location while offline", position.coords);
            db.location_updates.add(dto);
        }
    } catch (error) {
        // just log the error. It's background non-interfering stuff
        console.error(error);
        if (error instanceof GeolocationPositionError) {
        } else {
            Sentry.captureException(error);
        }
    }
}

export { updateUserLocation, getCurrentLocation };
