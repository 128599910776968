import { Button, Input, Stack, Textarea, useToast } from "@chakra-ui/core";
import styled from "@emotion/styled";
import React from "react";
import Quagga from "quagga"; // ES6
import { useState } from "react";
import { useLocationChange } from "../common/hooks";
import { Layout, LayoutBody } from "../components/layout";
import { colors } from "../custom-theme";
import { TabContent } from "../components/tab-content";
import { css } from "emotion";
import { useLocalStorage } from "react-use";
import { db } from "../services/db";
import Axios from "axios";

const Panel = styled.div`
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${colors.gray[400]};
`;
const SubPanel = styled.div`
    margin-bottom: 0.5rem;
`;

const DebugPage = () => {
    const toast = useToast();
    const [txt, setTxt] = useState("");
    const [orders, setOrders] = useState("");
    const [visits, setVisits] = useState("");
    const [value, setValue, remove] = useLocalStorage("last_sync_date", undefined);

    useLocationChange(() => {
        fetch();
    });

    const handleRemoveLastSyncDate = e => {
        remove();
    };

    const handleRemoveAll = e => {
        if (window.confirm("Bent u zeker? De volledige lokale database wordt gewist.")) {
            db.delete();
            window.localStorage.removeItem("column_settings");
            window.localStorage.removeItem("last_sync_date");
            window.localStorage.removeItem("visitor_report");
            window.localStorage.removeItem("current_visit");
            window.localStorage.removeItem("cart");
            window.localStorage.removeItem("camera_device");
            window.location.href = "/";
        }
    };

    async function fetch() {
        function getStringValue(key) {
            var tmp = window.localStorage.getItem(key);
            return `*** ${key.toUpperCase()}:
${tmp}

------------------------------------------

`;
        }
        var result = `*** CONFIG:
${JSON.stringify(window.appConfig)}

------------------------------------------        

`;

        result += getStringValue("token");
        result += getStringValue("current_visit");
        result += getStringValue("cart");
        result += getStringValue("user_info");
        result += getStringValue("visitor_report");
        result += getStringValue("column_settings");

        var devices = await Quagga.CameraAccess.enumerateVideoDevices();
        result += JSON.stringify(devices);

        // orders
        setOrders(JSON.stringify(await db.newOrders.toArray(), null, 2));

        // visit reports
        setVisits(JSON.stringify(await db.newVisits.toArray(), null, 2));

        setTxt(result);
    }

    const handleRemoveScannerItems = e => {
        window.localStorage.removeItem("scanner_items");
        alert("Done");
    };

    const handleCopy = async e => {
        await navigator.clipboard.writeText(`${txt}
ORDERS ********************************************************************************
${orders}
VISITS ********************************************************************************
${visits}`);
        toast({
            title: "Copy to clipboard",
            description: "OK",
            status: "success",
            isClosable: true
        });
    };

    const handleSendmail = async e => {
        var xxx = `${txt}
ORDERS ********************************************************************************
${orders}
VISITS ********************************************************************************
${visits}`;
        await Axios.post("/api/send-debug", {
            txt: xxx
        });
        toast({
            title: "Send via mail",
            description: "OK",
            status: "success",
            isClosable: true
        });
    };

    return (
        <Layout title={"Debug"}>
            <LayoutBody>
                <TabContent>
                    <Stack spacing="2">
                        <Panel>
                            <SubPanel>
                                <Button onClick={handleCopy} mr="2" variantColor="green">
                                    Copy to clipboard
                                </Button>
                                <Button onClick={handleSendmail} mr="2" variantColor="green">
                                    Send via mail
                                </Button>
                            </SubPanel>
                            <SubPanel>
                                Local storage last_sync_date: <span>{JSON.stringify(value)}</span>
                            </SubPanel>
                            <SubPanel>
                                <Button
                                    mr="2"
                                    variantColor="green"
                                    onClick={handleRemoveLastSyncDate}
                                >
                                    Verwijder last_sync_date
                                </Button>
                                <Button
                                    mr="2"
                                    variantColor="green"
                                    onClick={handleRemoveScannerItems}
                                >
                                    Verwijder multiscan items
                                </Button>
                                <Button variantColor="red" onClick={handleRemoveAll}>
                                    Alles verwijderen
                                </Button>
                            </SubPanel>
                        </Panel>
                        <Panel>
                            <strong>Debug info</strong>
                            <Textarea
                                className={css`
                                    background: white !important;
                                `}
                                height="400px"
                                width="80%"
                                fontFamily="monospace"
                                isReadOnly={true}
                                defaultValue={txt}
                            ></Textarea>
                        </Panel>
                        <Panel>
                            <strong>Orders</strong>
                            <Textarea
                                className={css`
                                    background: white !important;
                                `}
                                height="400px"
                                width="80%"
                                fontFamily="monospace"
                                isReadOnly={true}
                                defaultValue={orders}
                            ></Textarea>
                        </Panel>
                        <Panel>
                            <strong>Visit reports</strong>
                            <Textarea
                                className={css`
                                    background: white !important;
                                `}
                                height="400px"
                                width="80%"
                                fontFamily="monospace"
                                isReadOnly={true}
                                defaultValue={visits}
                            ></Textarea>
                        </Panel>
                    </Stack>
                </TabContent>
            </LayoutBody>
        </Layout>
    );
};

export { DebugPage };
