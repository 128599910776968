import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from "recoil";

const syncStatusState = atom({
    key: "syncStatus", // unique ID (with respect to other atoms/selectors)
    default: ""
});

const reportModalState = atom({ key: "reportModal", default: false });

const scannerCodeAtom = atom({
    key: "scannerCodeAtom",
    default: "-"
});

const scannerItemsStorage = window.localStorage.getItem("scanner_items");
const scannerItemsAtom = atom({
    key: "scannerItemsAtom",
    default: scannerItemsStorage ? JSON.parse(scannerItemsStorage) : []
});

export { syncStatusState, reportModalState, scannerCodeAtom, scannerItemsAtom };
