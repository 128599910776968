import React, { useState } from "react";
import { SettingsPanelForm } from "../../components/panel";
import { ButtonGroup, Button, IconButton, Flex, Box, useToast, Spinner } from "@chakra-ui/core";
import { FiTrash2, FiPlus, FiCheck } from "react-icons/fi";
import { InputFormControl } from "../../components/controls";
import arrayMutators from "final-form-arrays";
import { useLocationChange } from "../../common/hooks";
import { FieldArray } from "react-final-form-arrays";
import { deepCopy } from "../../common/utils";
import Axios from "axios";

const ItemList = ({ fields, meta }) => {
    const handleAdd = e => {
        fields.push({});
    };

    const handleRemove = index => e => {
        fields.remove(index);
    };

    return (
        <>
            {fields.map((name, index) => (
                <Flex mt={3} key={index}>
                    <InputFormControl
                        flexGrow="1"
                        mr={3}
                        size="sm"
                        name={`${name}.name`}
                    ></InputFormControl>
                    <IconButton
                        onClick={handleRemove(index)}
                        variantColor="red"
                        aria-label="Verwijderen"
                        size="sm"
                        icon={FiTrash2}
                    />
                </Flex>
            ))}
            <Box marginTop="20px">
                <Button type="button" onClick={handleAdd} leftIcon={FiPlus} size="sm">
                    Toevoegen
                </Button>
            </Box>
        </>
    );
};

const CompanyTypesSettings = () => {
    const [isSaving, setIsSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const toast = useToast();

    async function fetch() {
        setLoading(true);
        var res = await Axios.get("/api/companytype");
        setData({ items: res.data });
        setLoading(false);
    }

    useLocationChange(() => {
        fetch();
    });

    const onSubmit = async values => {
        setIsSaving(true);
        var data = deepCopy(values);
        try {
            await Axios.post("/api/companytype", data);
            fetch();
            toast({
                title: "Bedrijfstypes",
                description: "De gegevens zijn succesvol opgeslagen.",
                status: "success",
                isClosable: true
            });
            setIsSaving(false);
        } catch (error) {
            return error.response.data.errors;
        }
    };

    return (
        <>
            <SettingsPanelForm
                title="Bedrijfstypes"
                onSubmit={onSubmit}
                initialValues={data}
                mutators={{
                    ...arrayMutators
                }}
                actions={
                    <ButtonGroup size="sm" marginLeft="auto">
                        <Button
                            type="submit"
                            leftIcon={FiCheck}
                            isLoading={isSaving}
                            variantColor="green"
                            loadingText="Opslaan"
                        >
                            Opslaan
                        </Button>
                    </ButtonGroup>
                }
                render={({ handleSubmit, submitting, dirty, pristine, values }) => (
                    <>
                        {loading && (
                            <Spinner
                                thickness="2px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="red.500"
                                size="md"
                            />
                        )}
                        {!loading && (
                            <FieldArray name="items">
                                {({ fields }) => <ItemList fields={fields} />}
                            </FieldArray>
                        )}
                    </>
                )}
            />
        </>
    );
};

export { CompanyTypesSettings };
