import React from "react";
import { Switch, Route } from "react-router-dom";
import { Layout, LayoutSidebar, LayoutBody } from "../../components/layout";
import { CompanySearch } from "../../components/company/company-search";
import { CompanyList } from "../../components/company/company-list";
import { CompaniesMap } from "../../components/company/companies-map";
import { CompanyTabs } from "../../components/company/company-tabs";
import { CompanyHeader } from "../../components/company/company-header";

const CompaniesPage = () => {
    return (
        <Layout title="Klanten">
            <LayoutSidebar>
                <CompanySearch />
                <CompanyList />
            </LayoutSidebar>
            <LayoutBody>
                <Switch>
                    <Route exact path={"/companies"}>
                        <CompaniesMap />
                    </Route>
                    <Route path={"/companies/:id"}>
                        <CompanyHeader />
                        <CompanyTabs />
                    </Route>
                </Switch>
            </LayoutBody>
        </Layout>
    );
};

export { CompaniesPage };
