const SYNCSTATUS = {
    New: "NEW",
    Edited: "EDITED",
    Synced: "SYNCED"
};

const MESSAGES = {
    Sync: "SYNC",
    ColumnSettings: "COLUMNSETTINGS",
};

export { SYNCSTATUS, MESSAGES };
