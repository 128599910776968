import React from "react";
import arrayMutators from "final-form-arrays";
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    ButtonGroup,
    Radio
} from "@chakra-ui/core";
import {
    TextareaFormControl,
    RadioGroupControl,
    InputFormControl
} from "../../components/controls";
import { Field, Form } from "react-final-form";
import { FiCheck } from "react-icons/fi";
import { useEffect } from "react";
import { useState } from "react";
import { deepCopy } from "../../common/utils";

const VisitorReportModal = ({ isOpen, onClose }) => {
    const [data, setData] = useState();
    const [report, setReport] = useState(JSON.parse(window.localStorage.getItem("visitor_report")));

    useEffect(() => {
        if (isOpen) {
            // reset input
            var stor = window.localStorage.getItem("visitor_report");
            if (stor) {
                var rep = JSON.parse(stor);
                setReport(rep);
                setData(deepCopy(rep.content));
            } else {
                window.alert("Er is geen bezoeksrapport bekend. Gelieve te synchroniseren.")
            }
        }
    }, [isOpen]);

    const onSubmit = values => {
        onClose(values)(null);
    };

    return !report ? null : (
        <Form
            onSubmit={onSubmit}
            initialValues={data}
            mutators={{
                ...arrayMutators
            }}
            render={({ handleSubmit, submitting, dirty, pristine, values }) => (
                <Modal isOpen={isOpen} size="xl" closeOnOverlayClick={false} closeOnEsc={false}>
                    <form onSubmit={handleSubmit}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Bezoekrapport</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                {values.questions &&
                                    values.questions.map((question, questionIndex) => (
                                        <div key={questionIndex}>
                                            {question.type === "text" && (
                                                <TextareaFormControl
                                                    name={`questions[${questionIndex}].response`}
                                                    label={question.question}
                                                />
                                            )}
                                            {question.type === "number" && (
                                                <InputFormControl
                                                    name={`questions[${questionIndex}].response`}
                                                    label={question.question}
                                                    type="number"
                                                />
                                            )}
                                            {question.type === "multiple_choice" && (
                                                <Field
                                                    name={`questions[${questionIndex}].response`}
                                                    type="radio"
                                                    component={RadioGroupControl}
                                                    label={question.question}
                                                >
                                                    {question.answers.map((answer, idx) => (
                                                        <Radio key={idx} value={answer.answer}>
                                                            {answer.answer}
                                                        </Radio>
                                                    ))}
                                                </Field>
                                            )}
                                        </div>
                                    ))}
                            </ModalBody>

                            <ModalFooter>
                                <ButtonGroup size="sm">
                                    <Button onClick={onClose(false)}>Annuleren</Button>
                                    <Button variantColor="green" type="submit" leftIcon={FiCheck}>
                                        Opslaan
                                    </Button>
                                </ButtonGroup>
                            </ModalFooter>
                        </ModalContent>
                    </form>
                </Modal>
            )}
        />
    );
};

export { VisitorReportModal };
