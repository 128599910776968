import React, { useState, useCallback } from "react";
import { SettingsPanel } from "../../components/panel";
import { Table } from "../../components/table";
import { ButtonGroup, IconButton, Button, Spinner } from "@chakra-ui/core";
import { FiEdit2, FiTrash2, FiPlus } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { useLocationChange } from "../../common/hooks";
import { getUserInfo } from "../../common/user-info";
import Axios from "axios";

const UsersSettings = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    var userInfo = getUserInfo();

    async function fetch() {
        setLoading(true);
        var res = await Axios.get("/api/user");
        setData(res.data);
        setLoading(false);
    }

    useLocationChange(() => {
        fetch();
    });

    const handleDelete = id => async e => {
        if (window.confirm("Bent u zeker?")) {
            var res = await Axios.delete("/api/user/" + id);
            await fetch();
        }
    };

    const getGroup = value => {
        switch (value) {
            case "internal":
                return "Interne dienst";
                break;
            case "admin":
                return "Admin";
                break;

            default:
                return "Sales representative";
                break;
        }
    };

    const columns = React.useMemo(() => [
        {
            Header: "Naam",
            accessor: "name"
        },
        {
            Header: "Email",
            accessor: "email",
            Cell: ({ row }) => <a href={"mailto:" + row.original.email}>{row.original.email}</a>
        },
        {
            Header: "Functie",
            accessor: "function",
            Cell: ({ row }) => <span>{getGroup(row.original.roles)}</span>
        },
        {
            Header: "",
            accessor: "actions",
            disableSortBy: true,
            Cell: ({ row }) => (
                <ButtonGroup size="sm">
                    <IconButton
                        aria-label="Bewerken"
                        icon={FiEdit2}
                        as={NavLink}
                        to={`/settings/users/${row.original.id}/edit`}
                    />
                    <IconButton
                        isDisabled={userInfo.id === row.original.id}
                        onClick={handleDelete(row.original.id)}
                        variantColor="red"
                        aria-label="Verwijderen"
                        icon={FiTrash2}
                    />
                </ButtonGroup>
            )
        }
    ]);
    const handleSort = useCallback(sortBy => {
        // setTimeout(() => {
        //     let sorted = dummyData.slice();
        //     sorted.sort((a, b) => {
        //         for (let i = 0; i < sortBy.length; ++i) {
        //             if (a[sortBy[i].id] > b[sortBy[i].id]) return sortBy[i].desc ? -1 : 1;
        //             if (a[sortBy[i].id] < b[sortBy[i].id]) return sortBy[i].desc ? 1 : -1;
        //         }
        //         return 0;
        //     });
        //     setData(sorted);
        // }, 200);
    }, []);

    return (
        <>
            <SettingsPanel
                title="Gebruikers instellingen"
                actions={
                    <ButtonGroup size="sm">
                        <Button leftIcon={FiPlus} as={NavLink} to={`/settings/users/new/edit`}>
                            Nieuwe gebruiker
                        </Button>
                    </ButtonGroup>
                }
            >
                {loading && (
                    <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="red.500"
                        size="md"
                    />
                )}
                {!loading && <Table columns={columns} data={data} onSort={handleSort} />}
            </SettingsPanel>
        </>
    );
};

export { UsersSettings };
