import React, { useState } from "react";
import { css } from "emotion";
import { colors } from "../../custom-theme";
import { IconButton } from "@chakra-ui/core";
import { FiMinus, FiPlus, FiTrash2 } from "react-icons/fi";
import { UpDownControl } from "../controls";

const ProductLineItemStyles = css`
    padding: 10px 20px;
    border-bottom: 1px solid ${colors.gray[200]};
    display: flex;
    align-items: center;

    .body {
        flex-grow: 1;
        margin-right: 12px;

        h2 {
            display: block;
            font-weight: 600;
        }

        span {
            display: block;
            font-size: 13px;
            color: ${colors.gray[400]};
        }
    }
`;

const ProductLineItem = ({ lineItem, onRemove, onCountChange }) => {
    const handleCountChange = count => {
        onCountChange(lineItem, count);
    };

    const handleRemove = e => {
        onRemove(lineItem);
    };

    return (
        <div className={ProductLineItemStyles}>
            <div className="body">
                <h2>{lineItem.product.name}</h2>
                <span>{lineItem.code}</span>
            </div>
            <UpDownControl value={lineItem.count} onChange={handleCountChange} />
            <IconButton
                marginLeft="30px"
                variantColor="red"
                icon={FiTrash2}
                size="sm"
                onClick={handleRemove}
                ariaLabel="Verwijderen"
            />
        </div>
    );
};

export { ProductLineItem };
