import Axios from "axios";
import { db } from "./db";
import voca from "voca";

var status = {};

var isBusy = false;

function indexProduct(product) {
    // full
    var result = [product.code, product.name_upper, product.barcode];

    if (product.code.length > 2) {
        // code is longer than 2 chars. Split on the first '-' and also index the second part
        var split = product.code.split(/-(.+)/);
        if (split.length > 1) {
            result.push(split[1]);
        }
    }

    // product name parts split by space
    result = result.concat(voca.split(product.name_upper, " "));

    // keep only search strings that are longer than 1 char (ex. we filter + or -)
    return result.filter(x => !!x && x.length > 1).map(x => product.is_light + "__" + x);
}

function indexCompany(company) {
    // full
    var result = [company.code, company.name_upper];

    // name parts
    result = result.concat(voca.split(company.name_upper, " "));

    // keep only search strings that are longer than 1 char (ex. wwe filter + or -)
    return result.filter(
        x =>
            !!x &&
            x.length > 1 &&
            x != "BVBA" &&
            x != "SPRL" &&
            x != "VAN" &&
            x != "DEN" &&
            x != "bv" &&
            x != "nv"
    );
}

async function syncTable(
    tableName,
    apiUrl,
    friendlyName,
    callback,
    additionalIndexing,
    lastSyncDate
) {
    callback(`Synchroniseren: ${friendlyName}`, true);
    var res = await Axios.get(apiUrl, {
        params: {
            lastSyncDate: lastSyncDate
        }
    });
    var counter = 0;

    // add and update

    for (let item of res.data) {
        counter++;
        if (counter % 2 === 0) {
            callback(`Synchroniseren: ${friendlyName} (${counter}/${res.data.length})`, true);
        }
        var tmp = {
            ...item
        };
        if (additionalIndexing) {
            await additionalIndexing(tmp);
        }
        await db[tableName].put(tmp);
    }

    // delete TODO (aangezien we niet alles opvragen op server; enkel wijzigingen, moeten we delete anders doen)

    // var allItems = await db[tableName].toArray();
    // for (let item of allItems) {
    //     if (!res.data.find(x => x.id === item.id)) {
    //         await db[tableName].delete(parseInt(item.id));
    //     }
    // }

    callback();
    return res.data;
}

async function syncCreateXmlFiles(callback) {
    callback(`Synchroniseren: XML orders aanmaken`, true);

    var res = await Axios.post(`/api/order/xml-files`, {});

    callback();
}

async function syncLocationUpdates(callback) {
    callback(`Synchroniseren: locatie updates`, true);

    var counter = 0;
    var items = await db.location_updates.toArray();
    for (let item of items) {
        counter++;
        if (counter % 10 === 0) {
            callback(`Synchroniseren: locatie updates (${counter}/${res.data.length})`, true);
        }

        var res = await Axios.post(`/api/user/update-location`, {
            latitude: item.latitude,
            longitude: item.longitude
        });
        await db.location_updates.delete(item.id);
    }

    callback();
}

async function syncReport(callback, onSetLocalStorage) {
    callback(`Synchroniseren: bezoekrapport`, true);

    var res = await Axios.get(`/api/report`);

    onSetLocalStorage("visitor_report", res.data);

    callback();
}

async function syncNewOrders(callback) {
    callback(`Synchroniseren: Nieuwe bestellingen`, true);

    var orders = await db.newOrders.orderBy("id").toArray();
    console.log(orders);
    var counter = 0;
    for (let order of orders) {
        counter++;
        callback(`Synchroniseren: Nieuwe bestellingen (${counter}/${orders.length})`, true);
        await Axios.put("/api/order", order);

        await db.newOrders.delete(order.id);
    }
}

async function syncNewVisits(callback, reports_id) {
    callback(`Synchroniseren: Nieuwe bezoeken`, true);

    var visits = await db.newVisits.orderBy("id").toArray();
    console.log(visits);
    var counter = 0;
    for (let visit of visits) {
        counter++;
        callback(`Synchroniseren: Nieuwe bezoeken (${counter}/${visits.length})`, true);
        await Axios.put("/api/visit", { ...visit, reports_id: reports_id });

        await db.newVisits.delete(visit.id);
    }
}

async function sync(token, config, callback, onSetLocalStorage, lastSyncDate) {
    if (!token) {
        throw new Error("No token specified");
    }

    if (isBusy) {
        console.log("Already syncing. Do not start sync again.");
        return;
    }

    isBusy = true;

    // TODO: new companies should be created first, to be able to save orders

    // visits can be synced, no need to wait for new prospects. These are created online anyway.
    // first sync visits & orders to be inserted, when we sync the rest afterwards, the orders will come along (with ID filled in then)
    if (config.reports_id) {
        // only if we have an existing current report there are visits possible
        await syncNewVisits(callback, config.reports_id);
    }
    await syncNewOrders(callback);

    await syncTable("visits", "/api/visit", "Bezoeken", callback, null, lastSyncDate);
    await syncTable("orders", "/api/order", "Bestellingen", callback, null, lastSyncDate);

    await syncTable(
        "productgroups",
        "/api/productgroup",
        "Productgroepen",
        callback,
        async item => {
            item.name_upper = item.name.toUpperCase();
            item.parent_id = item.parent_id ? item.parent_id : "-";
        }
    );

    await syncTable("companytypes", "/api/companytype", "Bedrijfstypes", callback, item => {
        item.name_upper = item.name.toUpperCase();
    });
    await syncTable(
        "companies",
        "/api/company",
        "Bedrijven",
        callback,
        async item => {
            item.name_upper = item.name.toUpperCase();
            item.city_upper = item.city.toUpperCase();

            if (!item.last_visit) {
                // when indexing in Dexie, we need a value. So if there are no visits yet, we set it like this.
                item.last_visit = "";
            }

            // full text index of name
            item.name_search = indexCompany(item);
        },
        lastSyncDate
    );
    await syncTable(
        "products",
        "/api/product",
        "Producten",
        callback,
        async item => {
            item.name_upper = item.name.toUpperCase();

            // full text index of name
            item.is_light = item.is_light ? 1 : 0; // cannot index a boolean in indexedDB
            item.name_search = indexProduct(item); // first do is_light !!!!

            if (item.image_url && !item.is_light) {
                let imgRes;
                try {
                    imgRes = await Axios.get(item.image_url, {
                        responseType: "blob"
                    });
                } catch (error) {
                    if (error.response.status !== 404) throw error;
                }
                if (imgRes.data) {
                    await db.productImages.put({
                        id: item.id,
                        image: imgRes.data
                    });
                }
            }
        },
        lastSyncDate
    );

    await syncTable(
        "specialprices",
        "/api/specialprice",
        "Speciale prijzen",
        callback,
        async item => {
            // when indexing in Dexie, we need a value. So if there are no visits yet, we set it like this.
            if (!item.brand_id) {
                item.brand_id = "";
            }
            if (!item.companies_id) {
                item.companies_id = "";
            }
            if (!item.products_id) {
                item.products_id = "";
            }
        },
        lastSyncDate
    );

    // do this one last. Little less important than orders and such
    await syncLocationUpdates(callback);

    await syncCreateXmlFiles(callback);

    await syncReport(callback, onSetLocalStorage);

    isBusy = false;
}

export { sync };
