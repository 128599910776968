import { useCallback } from "react";
import { useRecoilState, atom } from "recoil";

// on page load, compose a default recoil state from either saved local storage, or a default one
const defaultVisit = {
    active: false
};
const defaultCart = {
    active: false,
    items: [],
    company: {}
};
const storageState = window.localStorage.getItem("current_visit");
const cartStorageState = window.localStorage.getItem("cart");
const currentVistAtom = atom({
    key: "current_state", // unique ID (with respect to other atoms/selectors)
    default: storageState ? JSON.parse(storageState) : defaultVisit
});
const cartAtom = atom({
    key: "cart", // unique ID (with respect to other atoms/selectors)
    default: cartStorageState ? JSON.parse(cartStorageState) : defaultCart
});

/** saves the current visit both in Local storage as in recoil state */
const useCurrentVisit = () => {
    const [recoilState, setRecoilState] = useRecoilState(currentVistAtom);

    const setter = useCallback(value => {
        setRecoilState(value);

        window.localStorage.setItem("current_visit", JSON.stringify(value));
    }, []);

    return [recoilState, setter];
};

const useCart = () => {
    const [recoilState, setRecoilState] = useRecoilState(cartAtom);

    const setter = useCallback(value => {
        setRecoilState(value);

        window.localStorage.setItem("cart", JSON.stringify(value));
    }, []);

    return [recoilState, setter];
};

export { useCurrentVisit, useCart, defaultCart, defaultVisit };
