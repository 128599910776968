import React, { useState, useEffect } from "react";
import { css } from "emotion";
import { Link, Stack } from "@chakra-ui/core";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { isAdmin } from "../../common/user-info";

const Settings = styled.div`
    position: relative;
    flex-grow: 1;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    min-height: 0;
`;

const SettingsWrapper = styled.div`
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow-y: auto;
    flex-grow: 1;
    padding: 20px;
    -webkit-overflow-scrolling: touch;
`;

const SettingsList = () => {
    const admin = isAdmin();

    return (
        <Settings>
            <SettingsWrapper>
                <Stack>
                    {admin && (
                        <>
                            <Link mb={3} as={NavLink} color="blue.500" to={`/settings/users`}>
                                Gebruikers
                            </Link>
                            <Link
                                mb={3}
                                as={NavLink}
                                color="blue.500"
                                to={`/settings/visitors-report`}
                            >
                                Bezoekrapport
                            </Link>
                            <Link
                                mb={3}
                                as={NavLink}
                                color="blue.500"
                                to={`/settings/company-types`}
                            >
                                Bedrijfstypes
                            </Link>
                        </>
                    )}
                    <Link mb={3} as={NavLink} color="blue.500" to={`/settings/change-password`}>
                        Wachtwoord wijzigen
                    </Link>
                </Stack>
            </SettingsWrapper>
        </Settings>
    );
};

export { SettingsList };
