import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import qs from "qs";
import { css } from "emotion";
import { FixedSizeList } from "react-window";
import { Button, Spinner } from "@chakra-ui/core";
import { FiPlus } from "react-icons/fi";
import { useConnection, useQueryParams } from "../../common/hooks";
import { listCompanyKeys } from "../../services/company-service";
import PubSub from "pubsub-js";
import { MESSAGES } from "../../common/const";
import { CompanyListItem } from "./company-listitem";
import { useMeasure } from "react-use";
import InfiniteLoader from "react-window-infinite-loader";
import produce from "immer";
import { db } from "../../services/db";

// necessary for the virtualisation(react-window)
const companyListItemHeight = 64;

const CompanyListStyles = css`
    position: relative;
    flex-grow: 1;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    min-height: 0;
`;

const CompanyListWrapperStyles = css`
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow-y: auto;
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
`;

const CompanyListButtonStyles = css`
    padding: 10px;
`;

const CompanyList = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const connection = useConnection();
    const routeMatch = useRouteMatch();
    const q = useQueryParams();
    const location = useLocation();
    const history = useHistory();

    const [ref, { width, height }] = useMeasure();
    let mounted = true;

    async function fetch() {
        setLoading(true);

        // fetch
        var keys = (await listCompanyKeys(q.s, q.sort)).map(x => ({
            id: x
        }));

        if (mounted) {
            setItems(keys);
            // todo: we need to prevent loading the list of companies every time another company is clicked
            // var items = await listCompanies(search, sort);
            // setItems(items);
            setLoading(false);
        }
    }

    useEffect(() => {
        var token = PubSub.subscribe(MESSAGES.Sync, (msg, data) => {
            fetch();
            var q = qs.parse(location.search, { ignoreQueryPrefix: true });
            if (q.s) delete q.s;
            history.push("?" + qs.stringify(q));
        });

        return () => {
            // unmount
            mounted = false;
            PubSub.unsubscribe(token);
        };
    }, []);

    useEffect(() => {
        console.log("route change");
        fetch();
    }, [routeMatch.url, q.s, q.sort]);

    const Row = ({ index, style }) => (
        <div style={style}>
            <CompanyListItem
                height={companyListItemHeight}
                key={items[index].id}
                id={items[index].id}
                company={items[index].data}
            />
        </div>
    );

    const isItemLoaded = index => !!items[index].data;
    const loadMoreItems = (startIndex, stopIndex) => {
        return new Promise(resolve => {
            var keys = items.slice(startIndex, stopIndex + 1).map(x => x.id);

            db.companies
                .where(":id")
                .anyOf(keys)
                .toArray()
                .then(sliceObjects => {
                    if (mounted) {
                        var next = produce(items, draft => {
                            for (let index = startIndex; index <= stopIndex; index++) {
                                var tmp = sliceObjects.find(x => x.id == draft[index].id);
                                draft[index].data = tmp;
                            }
                        });
                        setItems(next);
                    }

                    resolve();
                });
        });
    };

    return (
        <div className={CompanyListStyles}>
            {loading && (
                <Spinner
                    thickness="2px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="red.500"
                    size="md"
                    m={3}
                />
            )}
            {!loading && !!items.length && (
                <>
                    <div className={CompanyListWrapperStyles} ref={ref}>
                        <InfiniteLoader
                            isItemLoaded={isItemLoaded}
                            itemCount={items.length}
                            loadMoreItems={loadMoreItems}
                        >
                            {({ onItemsRendered, ref }) => (
                                <FixedSizeList
                                    height={height}
                                    itemCount={items.length}
                                    itemSize={companyListItemHeight}
                                    onItemsRendered={onItemsRendered}
                                    ref={ref}
                                    width={width}
                                >
                                    {Row}
                                </FixedSizeList>
                            )}
                        </InfiniteLoader>
                    </div>
                    <div className={CompanyListButtonStyles}>
                        <Button
                            isDisabled={!connection.online}
                            leftIcon={FiPlus}
                            variantColor="green"
                            width="100%"
                            as={NavLink}
                            to={"/companies/new/edit?tab=0"}
                        >
                            Prospect
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export { CompanyList };
