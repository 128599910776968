import React, { useState, useCallback } from "react";
import { TabContent } from "../../components/tab-content";
import { Button, Spinner, ButtonGroup, IconButton } from "@chakra-ui/core";

import { useLocationChange } from "../../common/hooks";
import { FiClock, FiEye, FiFileText } from "react-icons/fi";
import { Table } from "../../components/table";
import { formatCurrency, formatDate } from "../../helpers/format";
import { VisitorReportModal } from "../../components/company/visitor-report-modal";
import { db } from "../../services/db";
import { useEffect } from "react";
import { parse, parseISO } from "date-fns";
import { CompanyReportModal } from "../../components/company/company-report-modal";

// Bekijk bestelling
// Bekijk rapport => ficheke

const CompanyVisits = ({ companyId }) => {
    const [isOpenVisitorReport, setIsOpenVisitorReport] = useState(false);
    const onCloseVisitorReport = () => setIsOpenVisitorReport(false);
    const [data, setData] = useState([]);
    const [showVisitReportId, setShowVisitReportId] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetch() {
            if (!companyId || companyId === "new") return;
            var items = await db.visits
                .where("companies_id")
                .equals(parseInt(companyId))
                .reverse()
                .sortBy("id");
            setData(items);
            setLoading(false);
        }
        fetch();
    }, [companyId]);

    const columns = React.useMemo(() => [
        {
            Header: "Datum",
            accessor: "created_at",
            Cell: ({ row }) => row.values.created_at && formatDate(parseISO(row.values.created_at))
        },
        {
            Header: "Duur",
            accessor: "duration"
        },
        {
            Header: "Gebruiker",
            accessor: "user_created",
            Cell: ({ row }) => row.values.user_created && row.values.user_created.name
        },
        {
            Header: "Totale bestelling",
            accessor: "order",
            Cell: ({ row }) => (row.values.order ? formatCurrency(row.values.order.total) : null)
        },
        {
            Header: "",
            accessor: "actions",
            Cell: ({ row }) => (
                <ButtonGroup size="sm">
                    {row.original.hasFiche && (
                        <IconButton
                            aria-label="Bekijk bestelling"
                            icon={FiEye}
                            onClick={() => {
                                handleViewOrder(row.original.id);
                            }}
                        />
                    )}
                    <IconButton
                        aria-label="Bekijk bezoekrapport"
                        icon={FiFileText}
                        onClick={handleViewReport(row.original.id)}
                    />
                </ButtonGroup>
            ),
            disableSortBy: true
        }
    ]);

    const handleViewOrder = id => {
        console.log("handleViewOrder: ", id);
    };

    const handleViewReport = id => e => {
        setShowVisitReportId(id);
    };

    const handleCloseReport = e => {
        setShowVisitReportId(null);
    };

    const handleSort = useCallback(sortBy => {
        // @todo: sort on server
        // Settimout fakes server response.
        // setTimeout(() => {
        //     console.log("sort");
        //     let sorted = dummyData.slice();
        //     sorted.sort((a, b) => {
        //         for (let i = 0; i < sortBy.length; ++i) {
        //             if (a[sortBy[i].id] > b[sortBy[i].id]) return sortBy[i].desc ? -1 : 1;
        //             if (a[sortBy[i].id] < b[sortBy[i].id]) return sortBy[i].desc ? 1 : -1;
        //         }
        //         return 0;
        //     });
        //     setData(sorted);
        // }, 200);
    }, []);

    return (
        <>
            {loading && (
                <TabContent>
                    <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="red.500"
                        size="md"
                    />
                </TabContent>
            )}
            {!loading && (
                <TabContent title="Bezoeken">
                    <Table columns={columns} data={data} onSort={handleSort} />
                    <CompanyReportModal
                        isOpen={!!showVisitReportId}
                        visitId={showVisitReportId}
                        onClose={handleCloseReport}
                    />
                </TabContent>
            )}
        </>
    );
};

export { CompanyVisits };
