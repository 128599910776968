import { css } from "emotion";
import { colors } from "../../custom-theme";

const CartStyles = css`
    position: relative;
    flex-grow: 1;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow: hidden;
`;

const CartHeaderStyles = css`
    padding: 20px 10px;
    flex-shrink: 0;
    border-bottom: 1px solid ${colors.gray[200]};

    a {
        svg {
            margin-right: 6px;
        }
    }
`;
const CartBodyStyles = css`
    /* background: orange; */
    flex-grow: 1;
    min-height: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
`;

const CartFooterStyles = css`
    flex-shrink: 0;
    padding: 10px;
    padding-top: 16px;
    border-top: 1px solid ${colors.gray[200]};
    margin-top: -1px;

    > div {
        /* padding-top: 12px; */
        padding-bottom: 16px;
    }

    .row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;
    }

    .row-total {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
    }
`;

const CartItemStyles = css`
    padding: 10px;
    border-bottom: 1px solid ${colors.gray[200]};
    display: flex;
    align-items: flex-start;
    width: 100%;
    text-align: left;
    position: relative;
    .img {
        &:focus,
        &:active {
            outline: none;
        }
        cursor: pointer;
        display: block;
        width: 60px;
        margin-right: 10px;
        margin-top: 3px;
        border-radius: 3px;
        overflow: hidden;
        flex-shrink: 0;

        .img-aspect {
            position: relative;
            padding-top: 100%;
            height: 0;
            background-color: ${colors.gray[400]};

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            .img-placeholder {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 20px;
                height: 20px;
                color: ${colors.gray[300]};
            }
        }
    }
    .body {
        flex-grow: 1;
        position: relative;
        h2 {
            display: block;
            font-weight: 600;
            line-height: 1.25;
            margin-bottom: 2px;
        }

        span {
            display: block;
            font-size: 13px;
            color: ${colors.gray[400]};
        }
    }
`;

export { CartBodyStyles, CartFooterStyles, CartHeaderStyles, CartItemStyles, CartStyles };
