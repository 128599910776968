import React, { useState } from "react";
import { useHistory, useRouteMatch, NavLink } from "react-router-dom";
import { TabContent } from "../../components/tab-content";
import { colors } from "../../custom-theme";
import { v4 as uuidv4 } from "uuid";
import {
    IconButton,
    Button,
    ButtonGroup,
    Stack,
    Box,
    useDisclosure,
    Spinner,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialog,
    Select
} from "@chakra-ui/core";
import { FiEdit2, FiClock, FiPlus, FiTrash2, FiUser, FiCheck } from "react-icons/fi";
import { css } from "emotion";
import { formatPhoneHref } from "../../helpers/format";
import { useLocationChange, useConnection } from "../../common/hooks";
import { getCompany, deleteCompany } from "../../services/company-service";
import { useCurrentVisit, useCart } from "../../common/current-visit";
import { deepCopy } from "../../common/utils";
import { db } from "../../services/db";
import { reportModalState } from "../../atoms";
import { useRecoilState } from "recoil";
import { formatISO } from "date-fns/esm";
import { useEffect } from "react";
import Axios from "axios";

const FicheInfoStyles = css`
    display: block;
    /* line-height: 1; */
    border-bottom: 1px solid ${colors.gray[200]};
    padding-bottom: 6px;

    .label {
        display: block;
        font-weight: 600;
        font-size: 13px;
        color: ${colors.gray[400]};
    }

    .link,
    .value {
        display: block;
    }

    .value {
        min-height: 24px;
    }

    .link {
        color: ${colors.blue[400]};
    }
`;

const FicheInfo = ({ label, value, link, ...rest }) => {
    return (
        <Box className={FicheInfoStyles} {...rest}>
            <span className="label">{label}</span>
            {link && (
                <a className="link" href={link}>
                    {value}
                </a>
            )}
            {!link && <span className="value">{value}</span>}
        </Box>
    );
};

const CompanyFiche = () => {
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const connection = useConnection();
    const history = useHistory();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isAssignUserOpen,
        onOpen: onAssignUserOpen,
        onClose: onAssignUserClose
    } = useDisclosure();
    const cancelRef = React.useRef();
    const cancelAssignUserRef = React.useRef();
    const [currentVisit, setCurrentVisit] = useCurrentVisit();
    const [cart, setCart] = useCart();
    const [isOpenVisitorReport, setIsOpenVisitorReport] = useRecoilState(reportModalState);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    let mounted = true;

    const match = useRouteMatch();

    async function fetch() {
        const model = await getCompany(match.params.id);
        if (!model) {
            console.error("Company not found", match.params.id);
            history.push("/companies");
            return;
        }
        if (model.companytype_id) {
            model.companytype = await db.companytypes.get(model.companytype_id);
        }
        if (mounted) {
            setCompany(model);
            setLoading(false);
        }
    }

    useEffect(() => {
        return () => (mounted = false);
    }, []);

    useLocationChange(() => {
        fetch();
    });

    const handleOpenAssignUser = async () => {
        Axios
            .get("/api/user")
            .then(res => {
                setUsers(res.data);
            });

        onAssignUserOpen();
    };

    const startVisit = async () => {
        const clientKey = uuidv4();
        var visit = {
            client_key: clientKey,
            active: true,
            start_time: formatISO(new Date()),
            end_time: null,
            company: deepCopy(company),
            has_ordered: false
        };
        var visitId = await db.newVisits.add(visit);
        visit.id = visitId;
        setCart({
            visit_client_key: clientKey,
            company: deepCopy(company),
            items: []
        });
        setCurrentVisit(visit);
    };

    const handleStartStopVisit = () => {
        if (currentVisit.active) {
            setIsOpenVisitorReport(true);
        } else {
            // start a visit, immediately save it with status
            startVisit();
        }
    };

    const onDelete = () => {
        async function doDelete() {
            await Axios.delete(`/api/company/${match.params.id}`);
            await deleteCompany(match.params.id);
            onClose();

            history.push("/companies");
        }
        doDelete();
    };

    const canStartOrder = () => {
        if (!company.is_active) return false;

        if (currentVisit.active && company.id !== currentVisit.company.id) return false;

        return true;
    };

    const handleChangeUser = e => {
        setSelectedUser(e.target.value);
    };

    const handleAssignUser = async e => {
        console.log(selectedUser);
        var res = await Axios.post(`/api/company/${company.id}/salesrep`, {
            user_id: selectedUser
        });
        await db.companies.update(company.id, {
            salesrep: res.data
        });
        fetch();
        onAssignUserClose();
    };

    const isEditingDisabled = !company || !connection.online || company.record_type === "customer";

    return (
        <>
            {loading && (
                <TabContent>
                    <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="red.500"
                        size="md"
                    />
                </TabContent>
            )}
            {!loading && (
                <TabContent
                    company={company}
                    buttonsLeft={
                        <>
                            <ButtonGroup size="sm">
                                <Button
                                    leftIcon={FiClock}
                                    isDisabled={!canStartOrder()}
                                    onClick={handleStartStopVisit}
                                    variantColor="green"
                                >
                                    {currentVisit.active ? "Bezoek stoppen" : "Bezoek starten"}
                                </Button>
                                {isEditingDisabled && (
                                    <Button
                                        leftIcon={FiPlus}
                                        isDisabled={currentVisit.active || !company.is_active}
                                        as={
                                            currentVisit.active || !company.is_active
                                                ? "span"
                                                : NavLink
                                        }
                                        to={`/companies/${company.id}/orders/new`}
                                    >
                                        Remote bestelling
                                    </Button>
                                )}
                            </ButtonGroup>
                        </>
                    }
                    buttonsRight={
                        <>
                            <ButtonGroup size="sm" marginLeft="auto">
                                <Button
                                    isDisabled={isEditingDisabled}
                                    as={isEditingDisabled ? "div" : NavLink}
                                    to={isEditingDisabled ? "" : `/companies/${company.id}/edit`}
                                    leftIcon={FiEdit2}
                                >
                                    Bewerken
                                </Button>
                                {/* <IconButton
                                    isDisabled={!connection.online || company.record_type === "customer"}
                                    onClick={handleOpenAssignUser}
                                    icon={FiUser}
                                    ariaLabel="Verkoper toewijzen"
                                /> */}
                                <IconButton
                                    isDisabled={isEditingDisabled}
                                    variantColor="red"
                                    onClick={onOpen}
                                    icon={FiTrash2}
                                    ariaLabel="Verwijderen"
                                />
                            </ButtonGroup>

                            <AlertDialog
                                isOpen={isOpen}
                                onClose={onClose}
                                leastDestructiveRef={cancelRef}
                            >
                                <AlertDialogOverlay />
                                <AlertDialogContent>
                                    <AlertDialogHeader fontSize="md">
                                        Fiche verwijderen
                                    </AlertDialogHeader>
                                    <AlertDialogBody>
                                        Bent u zeker dat u deze fiche wilt verwijderen?
                                    </AlertDialogBody>
                                    <AlertDialogFooter>
                                        <ButtonGroup size="sm">
                                            <Button ref={cancelRef} onClick={onClose}>
                                                Annuleren
                                            </Button>
                                            <Button variantColor="red" onClick={onDelete}>
                                                Verwijderen
                                            </Button>
                                        </ButtonGroup>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                            <AlertDialog
                                isOpen={isAssignUserOpen}
                                onClose={onAssignUserClose}
                                leastDestructiveRef={cancelAssignUserRef}
                            >
                                <AlertDialogOverlay />
                                <AlertDialogContent>
                                    <AlertDialogHeader fontSize="md">
                                        Verkoper wijzigen
                                    </AlertDialogHeader>
                                    <AlertDialogBody>
                                        <Box mb={2}>
                                            Duid de verkoper aan die dit bedrijf beheert.
                                        </Box>
                                        <Select value={selectedUser} onChange={handleChangeUser}>
                                            <option value=""></option>
                                            {users.map(x => (
                                                <option key={x.id} value={x.id}>
                                                    {x.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </AlertDialogBody>
                                    <AlertDialogFooter>
                                        <ButtonGroup size="sm">
                                            <Button
                                                ref={cancelAssignUserRef}
                                                onClick={onAssignUserClose}
                                            >
                                                Annuleren
                                            </Button>
                                            <Button
                                                variantColor="green"
                                                leftIcon={FiCheck}
                                                onClick={handleAssignUser}
                                            >
                                                OK
                                            </Button>
                                        </ButtonGroup>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                        </>
                    }
                >
                    <Stack spacing={8}>
                        <Stack spacing={3}>
                            <FicheInfo label="Naam" value={company.name} />
                            <FicheInfo
                                label="Type"
                                value={company.companytype && company.companytype.name}
                            />
                            <FicheInfo
                                label="Verkoper"
                                value={company.salesrep && company.salesrep.name}
                            />
                            <Stack spacing={4} isInline>
                                <FicheInfo
                                    label="Straat + nr"
                                    value={company.address}
                                    width="50%"
                                    flexShrink="0"
                                />
                                <FicheInfo label="Postcode" value={company.zipcode} width="20%" />
                                <FicheInfo label="Stad" value={company.city} width="30%" />
                            </Stack>
                            <Stack spacing={4} isInline>
                                <FicheInfo
                                    label="Tel."
                                    value={company.phone}
                                    link={
                                        company.phone
                                            ? "tel:" + formatPhoneHref(company.phone)
                                            : null
                                    }
                                    width="50%"
                                    flexShrink="0"
                                />
                                <FicheInfo
                                    label="E-mail"
                                    value={company.email}
                                    link={company.email ? "mailto:" + company.email : null}
                                    width="50%"
                                />
                            </Stack>
                            <FicheInfo label="BTW" value={company.vat_number} />
                            <Stack spacing={4} isInline>
                                <FicheInfo
                                    label="Basiskorting"
                                    value={company.line_discount && company.line_discount + "%"}
                                    width="50%"
                                    flexShrink="0"
                                />
                                <FicheInfo
                                    label="Max. lijnkorting"
                                    value={company.max_discount && company.max_discount + "%"}
                                    width="50%"
                                />
                            </Stack>
                            <FicheInfo label="Informatie" value={company.info} />
                        </Stack>

                        {/* <Stack spacing={2}>
                    <Stack spacing={4} isInline>
                        <FicheInfo label="Prijzen tonen" value="Ja" width="50%" />
                        <FicheInfo label="Activeren" value="Ja" width="50%" />
                    </Stack>
                </Stack> */}
                    </Stack>
                </TabContent>
            )}
        </>
    );
};

export { CompanyFiche };
