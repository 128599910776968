import React, { useState } from "react";
import { TabContent } from "../../components/tab-content";
import { Spinner } from "@chakra-ui/core";
import { useRouteMatch } from "react-router-dom";
import { useLocationChange } from "../../common/hooks";
import { getCompany } from "../../services/company-service";
import styled from "@emotion/styled";
import { ProductCard } from "../../components/product/product-card";

const ProductsListWrapperStyles = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: -12px;
    margin-left: -12px;
`;

const ProductItem = styled.div(props => ({
    width: props.itemWidth,
    paddingTop: "12px",
    paddingLeft: "12px"
}));

const CompanyTopProducts = () => {
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const match = useRouteMatch();

    useLocationChange(() => {
        async function fetch() {
            // var model = await getCompany(match.params.id);
            // setCompany(model);
            setLoading(false);
        }
        fetch();
    });
    return (
        <>
            {loading && (
                <TabContent>
                    <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="red.500"
                        size="md"
                    />
                </TabContent>
            )}
            {!loading && (
                <TabContent title="Meest verkochte producten">
                    {/* <ProductsListWrapperStyles>
                        {dummyProducts.map(product => (
                            <ProductItem key={product.id} itemWidth={`${100 / 2}%`}>
                                <ProductCard product={product} showButton={false} />
                            </ProductItem>
                        ))}
                    </ProductsListWrapperStyles> */}
                </TabContent>
            )}
        </>
    );
};

export { CompanyTopProducts };
