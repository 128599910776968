import React, { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { CompaniesPage } from "../pages/company/companies";
import { ProductsPage } from "../pages/products/products";
import { SettingsPage } from "../pages/settings/settings";
import { OrderPage } from "../pages/order/order";
import { DebugPage } from "../pages/debug";
import { CurrentVisit } from "../components/current-visit";

const Routes = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            var tok = qs.parse(location.hash.substring(2)); // url is like https://xenex.test/#/access_token=xxxxxxx
            if (tok.access_token) {
                setToken(tok);
            }
        }
    }, []);

    return (
        <>
            <Switch>
                <Route path="/cart" component={OrderPage} />
                <Route
                    path="/companies/:company/orders/:id"
                    render={routeProps => <OrderPage isRemoteOrder={true} {...routeProps} />}
                />
                <Route path="/companies" component={CompaniesPage} />
                <Route path="/products" component={ProductsPage} />
                <Route path="/settings" component={SettingsPage} />
                <Route path="/debug" component={DebugPage} />
                <Redirect to="/companies" />
            </Switch>
            <CurrentVisit></CurrentVisit>
        </>
    );
};

export { Routes };
