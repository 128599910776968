import React, { useState, useCallback, useEffect } from "react";
import { TabContent } from "../../components/tab-content";
import {
    Button,
    Spinner,
    ButtonGroup,
    IconButton,
    ModalFooter,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody
} from "@chakra-ui/core";
import { FiEye } from "react-icons/fi";
import { Table } from "../../components/table";
import { formatCurrency, formatDate } from "../../helpers/format";
import { db } from "../../services/db";
import { parseISO } from "date-fns";

const CompanyOrders = ({ companyId }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [detailId, setDetailId] = useState(null);
    const [detail, setDetail] = useState(null);

    useEffect(() => {
        async function fetch() {
            if (!companyId || companyId === "new") return;
            var items = await db.orders
                .where("companies_id")
                .equals(parseInt(companyId))
                .reverse()
                .sortBy("id");
            setData(items);
            setLoading(false);
        }
        fetch();
    }, [companyId]);

    useEffect(() => {
        async function fetch(id) {
            let data = await db.orders.get(id);
            for (let line of data.order_lines) {
                let prod = await db.products.get(line.products_id);
                line.product = prod;
            }
            console.log("eeee", data);
            setDetail(JSON.parse(JSON.stringify(data)));
        }
        if (detailId) {
            // load order
            fetch(detailId);
        }
    }, [detailId]);

    const columns = React.useMemo(() => [
        {
            Header: "Datum",
            accessor: "created_at",
            Cell: ({ row }) => row.values.created_at && formatDate(parseISO(row.values.created_at))
        },
        {
            Header: "Gebruiker",
            accessor: "user_created",
            Cell: ({ row }) => (row.values.user_created ? row.values.user_created.name : "-")
        },
        {
            Header: "Status",
            accessor: "status"
        },
        {
            Header: "Totaal",
            accessor: "total",
            Cell: ({ row }) => row.values.total !== undefined && formatCurrency(row.values.total)
        },
      
        {
            Header: "",
            accessor: "actions",
            disableSortBy: true,
            Cell: ({ row }) => (
                <ButtonGroup size="sm">
                    <IconButton
                        aria-label="Bekijk bestelling"
                        icon={FiEye}
                        onClick={() => {
                            setDetailId(row.original.id);
                        }}
                    />
                </ButtonGroup>
            )
        }
    ]);

    const detailColumns = React.useMemo(() => [
        {
            Header: "Product",
            accessor: "product",
            Cell: ({ row }) => row.values.product.name
        },
        {
            Header: "Aantal",
            accessor: "count"
        },
        {
            Header: "# Geleverd",
            accessor: "delivered"
        },
        {
            Header: "Prijs",
            accessor: "price_piece"
        },
        {
            Header: "Korting",
            accessor: "discount"
        }
    ]);

    const handleSort = useCallback(sortBy => {}, []);

    const onCloseDetail = e => {
        setDetailId(null);
        setDetail(null);
    };

    return (
        <>
            {loading && (
                <TabContent>
                    <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="red.500"
                        size="md"
                    />
                </TabContent>
            )}
            {!loading && (
                <TabContent title="Bestellingen">
                    <Table columns={columns} data={data} onSort={handleSort} />
                    {!!detail && (
                        <Modal isOpen={!!detail} size="xl" onClose={onCloseDetail}>
                            <ModalOverlay />
                            <ModalContent maxW="1200px">
                                <ModalHeader>
                                    Bestelling - {formatDate(parseISO(detail.created_at))}
                                </ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Table
                                        columns={detailColumns}
                                        data={detail.order_lines}
                                        onSort={handleSort}
                                    />
                                </ModalBody>

                                <ModalFooter>
                                    <ButtonGroup size="sm">
                                        <Button variantColor="green" onClick={onCloseDetail}>
                                            Sluiten
                                        </Button>
                                    </ButtonGroup>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    )}
                </TabContent>
            )}
        </>
    );
};

export { CompanyOrders };
