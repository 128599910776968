import Axios from "axios";

function setAuthHeaderDefault(token) {
    Axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

function configAxiosDefaults() {
    // Add a response interceptor
    Axios.interceptors.response.use(
        response => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        error => {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (error.response.status === 500) {
                if (window)
                    window.alert("Onvoorziene fout. Probeer opnieuw of contacteer Keplerstein.");
                else console.error("Onvoorziene fout. Probeer opnieuw of contacteer Keplerstein.");
            }

            if (error.response.status === 401 && window) {
                // if the API returns a unautorized response, we need to refresh all login tokens.
                window.location.href = "/logout";
            }

            return Promise.reject(error);
        }
    );
}

export { configAxiosDefaults, setAuthHeaderDefault };
