import React, { useEffect, useRef } from "react";
import { css } from "emotion";
import {
    InputGroup,
    InputRightElement,
    Box,
    Input,
    Select,
    Stack,
    Button,
    useToast
} from "@chakra-ui/core";
import { FiSearch, FiX } from "react-icons/fi";
import { CameraScanner } from "../../components/scanner/barcode-scanner";
import qs from "qs";
import { useState } from "react";
import { useConnection, useDebounceEffect, useLocationChange } from "../../common/hooks";
import { useHistory, useLocation } from "react-router-dom";
import { BiImport } from "react-icons/bi";
import Axios from "axios";

const ProductsSearchStyles = css`
    position: relative;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
    padding: 10px;
    margin-bottom: 20px;
`;

const ProductsSearch = ({ showSort = false, showScanner = false }) => {
    const location = useLocation();
    const uploadRef = useRef();
    const toast = useToast();
    const connectionState = useConnection();
    const [uploading, setUploading] = useState(false);
    const [search, setSearch] = useState(() => {
        var q = qs.parse(location.search, { ignoreQueryPrefix: true });
        return q.s || "";
    });
    useLocationChange(location => {
        var q = qs.parse(location.search, { ignoreQueryPrefix: true });
        var tmp = q.s || "";
        setSearch(tmp);
    });
    const history = useHistory();
    const debouncedSearch = useDebounceEffect(search, 400);

    const handleChangeSearch = e => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        var q = qs.parse(location.search, { ignoreQueryPrefix: true });
        q.s = search;
        history.push("?" + qs.stringify(q));
    }, [debouncedSearch]);

    const handleFilesChanged = e => {
        setUploading(true);
        let file = e.target.files[0];

        let formData = new FormData();
        formData.append("excel_file", file);

        Axios.post(`/api/product/stock-file`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        }).then(() => {
            toast({
                title: "Het bestand is succesvol geimporteerd.",
                status: "success",
                isClosable: true
            });
            setUploading(false);
        });
    };

    const handleUploadAttachment = async e => {
        uploadRef.current.value = null;
        uploadRef.current.click();
    };

    return (
        <div className={ProductsSearchStyles}>
            <Stack spacing="2" isInline>
                <InputGroup width="200px">
                    <InputRightElement>
                        {!search && <Box as={FiSearch} size="32px" padding="1" />}
                        {!!search && (
                            <Box
                                className={css`
                                    cursor: pointer;
                                `}
                                as={FiX}
                                size="32px"
                                padding="1"
                                onClick={() => setSearch("")}
                            />
                        )}
                    </InputRightElement>
                    <Input
                        variant="filled"
                        onChange={handleChangeSearch}
                        value={search}
                        rounded="5px"
                        type="text"
                        placeholder="Zoeken..."
                    />
                </InputGroup>
                {showSort && (
                    <Select
                        width="200px"
                        rounded="5px"
                        placeholder="Sorteren op..."
                        iconSize="24px"
                    >
                        <option value="name_asc">Naam oplopend</option>
                        <option value="name_desc">Naam aflopend</option>
                        <option value="township_asc">Gemeente oplopend</option>
                        <option value="township_desc">Gemeente aflopend</option>
                        <option value="last_visit_asc">Laatste bezoek oplopend</option>
                        <option value="last_visit_desc">Laatste bezoek aflopend</option>
                    </Select>
                )}

                <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={handleFilesChanged}
                    ref={uploadRef}
                    className={css`
                        opacity: 0;
                        height: 0px;
                        width: 0px;
                        top: bottom;
                        right: 0;
                        visibility: hidden;
                        pointer-events: none;
                        position: fixed;
                        z-index: -1;
                    `}
                />
                <Box marginLeft="auto">
                    {connectionState.online && !showScanner && (
                        <Button
                            leftIcon={BiImport}
                            isLoading={uploading}
                            onClick={handleUploadAttachment}
                            title="Stockfile importeren"
                            marginLeft="auto"
                        >
                            Import stockfile
                        </Button>
                    )}

                    {showScanner && <CameraScanner ml={3} />}
                </Box>
            </Stack>
        </div>
    );
};

export { ProductsSearch };
