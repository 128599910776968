import React, { useEffect, useState } from "react";
import { css } from "emotion";
import Quagga from "quagga"; // ES6
import styled from "@emotion/styled";
import { Scanner } from "./scanner";
import { useCurrentVisit } from "../../common/current-visit";
import { useRecoilValue } from "recoil";
import { scannerCodeAtom } from "../../atoms";
import { useLocalStorage } from "react-use";
import { useScannerItems } from "../../common/hooks";
import { composeNextScannerItems } from "./compose-next-scanner-items";

const VideoPanel = styled.div`
    width: 100%;
    height: 100%;
`;

const VideoContent = styled.div`
    background: black;
    width: 100%;
    height: 100%;
    div {
        width: 100%;
        height: 100%;
        video {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
        }
    }
`;

const ButtonPanel = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.45);
    bottom: 0;
    left: 0;
    right: 0;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ScannerButton = styled.button`
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: white;
    /* border: 5px solid rgba(white, 0.8); */

    &::after {
        content: " ";
        position: absolute;
        z-index: -1;
        top: -8px;
        left: -8px;
        right: -8px;
        bottom: -8px;
        border: 5px solid white;
        border-radius: 100%;
    }

    &:focus,
    &:active {
        opacity: 0.6;
    }

    opacity: ${props => (props.isDisabled ? 0.5 : 0.99)};
    cursor: ${props => (props.isDisabled ? "not-allowed" : "pointer")};
`;

const InfoPanel = styled.div`
    position: absolute;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.65);
    top: 0;
    left: 0;
    right: 0;
    height: 76px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 0 20px;

    strong {
        font-weight: 600;
    }
`;

const CameraScannerPanelStyles = css`
    position: relative;
    min-height: 0;
    flex-shrink: 0;
    height: 100%;
    width: 50%;
`;

const CameraScannerPanel = () => {
    const [devices, setDevices] = useState([]);
    const [currentVisit] = useCurrentVisit();
    const [deviceId, setDeviceId] = useLocalStorage("camera_device", "");
    const currentCode = useRecoilValue(scannerCodeAtom);
    const [scannerItems, setScannerItems] = useScannerItems();

    const enumDevices = async () => {
        var devices = await Quagga.CameraAccess.enumerateVideoDevices();

        var items = devices.map(device => ({
            id: device.deviceId || device.id,
            name: device.label || device.deviceId || device.id
        }));
        var foundDevice = items.find(x => x.id === deviceId);

        setDevices(items);

        // first device as default
        if (deviceId && !foundDevice) setDeviceId(items[0].id);
        if (items.length && !deviceId) setDeviceId(items[0].id);
    };

    useEffect(() => {
        enumDevices();
    }, []);

    const handleAddProduct = async () => {
        if (currentCode != "-") {
            var next = await composeNextScannerItems(currentCode, scannerItems, currentVisit);
            if (next) {
                setScannerItems(next);
            }
        }
    };

    const handleDeviceChange = e => {
        setDeviceId(e.target.value);
    };

    return (
        <div className={CameraScannerPanelStyles}>
            <InfoPanel>
                <select
                    className={css`
                        color: white;
                        appearance: none;
                        background: transparent;
                        border: none;
                        display: block;
                    `}
                    value={deviceId}
                    onChange={handleDeviceChange}
                >
                    {devices.map(x => (
                        <option key={x.id} value={x.id}>
                            {x.name}
                        </option>
                    ))}
                </select>
                <span>
                    <strong>Code:</strong> {currentCode ? currentCode : "Scan product..."}
                </span>
            </InfoPanel>
            <VideoPanel>
                <VideoContent>{deviceId && <Scanner deviceId={deviceId}></Scanner>}</VideoContent>
            </VideoPanel>
            <ButtonPanel>
                <ScannerButton
                    isDisabled={!currentCode}
                    disabled={!currentCode}
                    onClick={handleAddProduct}
                />
            </ButtonPanel>
        </div>
    );
};

export { CameraScannerPanel };
