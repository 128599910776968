import React, { useState, useCallback } from "react";
import { TabContent } from "../../components/tab-content";
import { Spinner } from "@chakra-ui/core";
import { useRouteMatch } from "react-router-dom";
import { useLocationChange } from "../../common/hooks";
import { Table } from "../../components/table";
import { formatPhoneHref } from "../../helpers/format";
import { getCompany } from "../../services/company-service";

const CompanyContacts = () => {
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const match = useRouteMatch();

    useLocationChange(() => {
        async function fetch() {
            var model = await getCompany(match.params.id);
            setCompany(model);
            setLoading(false);
        }
        if (match.params.id !== "new") fetch();
    });

    const columns = React.useMemo(() => [
        {
            Header: "Naam",
            accessor: "name"
        },
        {
            Header: "E-mail",
            accessor: "email",
            Cell: ({ row }) => <a href={`mailto:${row.values.email}`}>{row.values.email}</a>
        },
        {
            Header: "Tel",
            accessor: "phone",
            Cell: ({ row }) => (
                <a href={`tel:${formatPhoneHref(row.values.phone)}`}>{row.values.phone}</a>
            )
        }
    ]);

    const handleSort = useCallback(sortBy => {
        // // @todo: sort on server
        // // Settimout fakes server response.
        // setTimeout(() => {
        //     let sorted = dummyData.slice();
        //     sorted.sort((a, b) => {
        //         for (let i = 0; i < sortBy.length; ++i) {
        //             if (a[sortBy[i].id] > b[sortBy[i].id]) return sortBy[i].desc ? -1 : 1;
        //             if (a[sortBy[i].id] < b[sortBy[i].id]) return sortBy[i].desc ? 1 : -1;
        //         }
        //         return 0;
        //     });
        //     setData(sorted);
        // }, 200);
    }, []);

    return (
        <>
            {loading && (
                <TabContent>
                    <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="red.500"
                        size="md"
                    />
                </TabContent>
            )}
            {!loading && !!company && !!company.contacts && (
                <TabContent title="Contactpersonen">
                    <Table columns={columns} data={company.contacts} onSort={handleSort} />
                </TabContent>
            )}
        </>
    );
};

export { CompanyContacts };
