import React, { useEffect, useRef, useState, useLayoutEffect, useCallback } from "react";
import Quagga from "quagga"; // ES6
import { useRecoilState } from "recoil";
import { scannerCodeAtom } from "../../atoms";

function getMedian(arr) {
    arr.sort((a, b) => a - b);
    const half = Math.floor(arr.length / 2);
    if (arr.length % 2 === 1) {
        return arr[half];
    }
    return (arr[half - 1] + arr[half]) / 2;
}

function getMedianOfCodeErrors(decodedCodes) {
    const errors = decodedCodes.filter(x => x.error !== undefined).map(x => x.error);
    const medianOfErrors = getMedian(errors);
    return medianOfErrors;
}

const Scanner = ({ deviceId, onScannerReady }) => {
    const [currentCode, setCurrentCode] = useRecoilState(scannerCodeAtom);

    const handleDetected = code => {
        setCurrentCode(code);
    };

    const errorCheck = useCallback(result => {
        const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
        // if Quagga is at least 75% certain that it read correctly, then accept the code.
        if (err < 0.25) {
            handleDetected(result.codeResult.code);
        }
    }, []);

    useLayoutEffect(() => {
        Quagga.init(
            {
                inputStream: {
                    type: "LiveStream",
                    constraints: {
                        width: { min: 640 },
                        height: { min: 480 },
                        facingMode: "environment",
                        deviceId: deviceId,
                        aspectRatio: { min: 1, max: 2 }
                    }
                },
                frequency: 2, // scans per second (for cpu bit on the low side)
                locator: {
                    patchSize: "medium",
                    halfSample: true
                },
                numOfWorkers: navigator.hardwareConcurrency,
                frequency: 10,
                decoder: {
                    readers: [
                        {
                            format: "ean_reader",
                            config: {}
                        },
                        {
                            format: "upc_reader",
                            config: {}
                        }
                    ]
                },
                locate: true
            },
            err => {
                if (err) {
                    return console.log("Error starting Quagga:", err);
                }
                Quagga.start();
                if (onScannerReady) {
                    onScannerReady();
                }
            }
        );
        Quagga.onDetected(errorCheck);
        return () => {
            Quagga.offDetected(errorCheck);
            Quagga.stop();
        };
    }, [deviceId, onScannerReady]);
    return <div id="interactive" className="viewport"></div>;
};

export { Scanner };
