// const space = {
//     "0": "0",
//     "1": "0.4rem",
//     "2": "0.8rem",
//     "3": "1.2rem",
//     "4": "1.6rem",
//     "5": "2rem",
//     "6": "2.4rem",
//     "8": "3.2rem",
//     "10": "4rem",
//     "12": "4.8rem",
//     "16": "6.4rem",
//     "20": "8rem",
//     "24": "9.6rem",
//     "32": "12.8rem",
//     "40": "16rem",
//     "48": "19.2rem",
//     "56": "22.4rem",
//     "64": "25.6rem",
//     px: "1px"
// };

// const sizes = {
//     "0": "0",
//     "1": "0.4rem",
//     "2": "0.8rem",
//     "3": "1.2rem",
//     "4": "1.6rem",
//     "5": "2rem",
//     "6": "2.4rem",
//     "8": "3.2rem",
//     "10": "4rem",
//     "12": "4.8rem",
//     "16": "6.4rem",
//     "20": "8rem",
//     "24": "9.6rem",
//     "32": "12.8rem",
//     "40": "16rem",
//     "48": "19.2rem",
//     "56": "22.4rem",
//     "64": "25.6rem",
//     px: "1px",
//     full: "100%",
//     "3xs": "22.4rem",
//     "2xs": "25.6rem",
//     xs: "32rem",
//     sm: "38.4rem",
//     md: "44.8rem",
//     lg: "51.2rem",
//     xl: "57.6rem",
//     "2xl": "67.2rem",
//     "3xl": "76.8rem",
//     "4xl": "89.6rem",
//     "5xl": "102.4rem",
//     "6xl": "115.2rem",
//     containers: { sm: "640px", md: "768px", lg: "1024px", xl: "1280px" }
// };

const colors = {
    transparent: "transparent",
    current: "currentColor",
    black: "#000",
    white: "#fff",
    whiteAlpha: {
        "50": "rgba(255, 255, 255, 0.04)",
        "100": "rgba(255, 255, 255, 0.06)",
        "200": "rgba(255, 255, 255, 0.08)",
        "300": "rgba(255, 255, 255, 0.16)",
        "400": "rgba(255, 255, 255, 0.24)",
        "500": "rgba(255, 255, 255, 0.36)",
        "600": "rgba(255, 255, 255, 0.48)",
        "700": "rgba(255, 255, 255, 0.64)",
        "800": "rgba(255, 255, 255, 0.80)",
        "900": "rgba(255, 255, 255, 0.92)"
    },
    blackAlpha: {
        "50": "rgba(0, 0, 0, 0.04)",
        "100": "rgba(0, 0, 0, 0.06)",
        "200": "rgba(0, 0, 0, 0.08)",
        "300": "rgba(0, 0, 0, 0.16)",
        "400": "rgba(0, 0, 0, 0.24)",
        "500": "rgba(0, 0, 0, 0.36)",
        "600": "rgba(0, 0, 0, 0.48)",
        "700": "rgba(0, 0, 0, 0.64)",
        "800": "rgba(0, 0, 0, 0.80)",
        "900": "rgba(0, 0, 0, 0.92)"
    },
    gray: {
        "50": "#F7FAFC",
        "100": "#EDF2F7",
        "200": "#E2E8F0",
        "300": "#CBD5E0",
        "400": "#A0AEC0",
        "500": "#718096",
        "600": "#4A5568",
        "700": "#2D3748",
        "800": "#1A202C",
        "900": "#171923"
    },
    red: {
        "50": "#ffe3e5",
        "100": "#ffb5b7",
        "200": "#f9868a",
        "300": "#f5565b",
        "400": "#f1272e",
        "500": "#d80e14",
        "600": "#a9080f",
        "700": "#790409",
        "800": "#4b0004",
        "900": "#200000"
    },
    orange: {
        "50": "#FFFAF0",
        "100": "#FEEBC8",
        "200": "#FBD38D",
        "300": "#F6AD55",
        "400": "#ED8936",
        "500": "#DD6B20",
        "600": "#C05621",
        "700": "#9C4221",
        "800": "#7B341E",
        "900": "#652B19"
    },
    yellow: {
        "50": "#fffff0",
        "100": "#fefcbf",
        "200": "#faf089",
        "300": "#f6e05e",
        "400": "#ecc94b",
        "500": "#d69e2e",
        "600": "#b7791f",
        "700": "#975a16",
        "800": "#744210",
        "900": "#5F370E"
    },
    green: {
        "50": "#f0fff4",
        "100": "#c6f6d5",
        "200": "#9ae6b4",
        "300": "#68d391",
        "400": "#48bb78",
        "500": "#38a169",
        "600": "#2f855a",
        "700": "#276749",
        "800": "#22543d",
        "900": "#1C4532"
    },
    teal: {
        "50": "#E6FFFA",
        "100": "#B2F5EA",
        "200": "#81E6D9",
        "300": "#4FD1C5",
        "400": "#38B2AC",
        "500": "#319795",
        "600": "#2C7A7B",
        "700": "#285E61",
        "800": "#234E52",
        "900": "#1D4044"
    },
    blue: {
        "50": "#ddf5ff",
        "100": "#b3dbfe",
        "200": "#86c1f9",
        "300": "#57a9f3",
        "400": "#2a90ee",
        "500": "#1176d5",
        "600": "#055ca7",
        "700": "#004278",
        "800": "#00274b",
        "900": "#000e1f"
    },
    cyan: {
        "50": "#EDFDFD",
        "100": "#C4F1F9",
        "200": "#9DECF9",
        "300": "#76E4F7",
        "400": "#0BC5EA",
        "500": "#00B5D8",
        "600": "#00A3C4",
        "700": "#0987A0",
        "800": "#086F83",
        "900": "#065666"
    },
    purple: {
        "50": "#faf5ff",
        "100": "#e9d8fd",
        "200": "#d6bcfa",
        "300": "#b794f4",
        "400": "#9f7aea",
        "500": "#805ad5",
        "600": "#6b46c1",
        "700": "#553c9a",
        "800": "#44337a",
        "900": "#322659"
    },
    pink: {
        "50": "#fff5f7",
        "100": "#fed7e2",
        "200": "#fbb6ce",
        "300": "#f687b3",
        "400": "#ed64a6",
        "500": "#d53f8c",
        "600": "#b83280",
        "700": "#97266d",
        "800": "#702459",
        "900": "#521B41"
    },
    linkedin: {
        "50": "#E8F4F9",
        "100": "#CFEDFB",
        "200": "#9BDAF3",
        "300": "#68C7EC",
        "400": "#34B3E4",
        "500": "#00A0DC",
        "600": "#008CC9",
        "700": "#0077B5",
        "800": "#005E93",
        "900": "#004471"
    },
    facebook: {
        "50": "#E8F4F9",
        "100": "#D9DEE9",
        "200": "#B7C2DA",
        "300": "#6482C0",
        "400": "#4267B2",
        "500": "#385898",
        "600": "#314E89",
        "700": "#29487D",
        "800": "#223B67",
        "900": "#1E355B"
    },
    messenger: {
        "50": "#D0E6FF",
        "100": "#B9DAFF",
        "200": "#A2CDFF",
        "300": "#7AB8FF",
        "400": "#2E90FF",
        "500": "#0078FF",
        "600": "#0063D1",
        "700": "#0052AC",
        "800": "#003C7E",
        "900": "#002C5C"
    },
    whatsapp: {
        "50": "#e2f7f4",
        "100": "#c3f0e9",
        "200": "#a0e7dc",
        "300": "#76dccd",
        "400": "#43cfba",
        "500": "#00BFA5",
        "600": "#00ac92",
        "700": "#009780",
        "800": "#007d6a",
        "900": "#005a4c"
    },
    twitter: {
        "50": "#e5f4fd",
        "100": "#c8e9fb",
        "200": "#a8dcfa",
        "300": "#83cdf7",
        "400": "#57bbf5",
        "500": "#1DA1F2",
        "600": "#1a94da",
        "700": "#1681bf",
        "800": "#136b9e",
        "900": "#0d4d71"
    },
    telegram: {
        "50": "#e3f2f9",
        "100": "#c5e4f3",
        "200": "#a2d4ec",
        "300": "#7ac1e4",
        "400": "#47a9da",
        "500": "#0088CC",
        "600": "#007ab8",
        "700": "#006ba1",
        "800": "#005885",
        "900": "#003f5e"
    }
};

// const fontSizes = {
//     xs: "1.2rem",
//     sm: "1.4rem",
//     md: "1.6rem",
//     lg: "1.8rem",
//     xl: "2rem",
//     "2xl": "2.4rem",
//     "3xl": "3rem",
//     "4xl": "3.6rem",
//     "5xl": "4.8rem",
//     "6xl": "6.4rem"
// };

export { colors };
