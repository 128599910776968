import React from "react";
import { css } from "emotion";
import { SyncStatus } from "./sync-status";

const LayoutStyles = css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const LayoutHeadingStyles = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fafafa;
    width: 100%;
    height: 74px;
    padding: 20px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
    flex-shrink: 0;

    h1 {
        font-size: 20px;
        font-weight: 600;
    }
`;

const LayoutInnerStyles = css`
    display: flex;
    padding: 20px;
    min-height: 0;
    flex-grow: 1;
`;

const LayoutSidebarStyles = css`
    display: flex;
    flex-direction: column;
    width: 350px;
    margin-right: 20px;
    flex-shrink: 0;
`;

const LayoutBodyStyles = css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
`;

const Layout = ({ children, title }) => {
    return (
        <div className={LayoutStyles}>
            <LayoutHeading title={title}></LayoutHeading>
            <LayoutInner>{children}</LayoutInner>
        </div>
    );
};

const LayoutHeading = ({ children, title, ...rest }) => {
    return (
        <div className={LayoutHeadingStyles} {...rest}>
            <h1>{title}</h1>
            <SyncStatus></SyncStatus>
        </div>
    );
};

const LayoutInner = ({ children, ...rest }) => {
    return (
        <div className={LayoutInnerStyles} {...rest}>
            {children}
        </div>
    );
};

const LayoutSidebar = ({ children, ...rest }) => {
    return (
        <div className={LayoutSidebarStyles} {...rest}>
            {children}
        </div>
    );
};

const LayoutBody = ({ children, ...rest }) => {
    return (
        <div className={LayoutBodyStyles} {...rest}>
            {children}
        </div>
    );
};

export { Layout, LayoutSidebar, LayoutBody };
