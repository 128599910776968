import React, { useState } from "react";
import { SettingsPanelForm } from "../../components/panel";
import {
    ButtonGroup,
    Button,
    Stack,
    FormControl,
    IconButton,
    Flex,
    Spinner
} from "@chakra-ui/core";
import { FiCheck, FiTrash2, FiPlus, FiChevronUp, FiChevronDown } from "react-icons/fi";
import { colors } from "../../custom-theme";
import styled from "@emotion/styled";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { InputFormControl } from "../../components/controls";
import { useToast } from "@chakra-ui/core";
import { useLocationChange } from "../../common/hooks";
import Axios from "axios";

const QuestionItem = styled.div`
    border: 1px solid ${colors.gray[200]};
    border-radius: 5px;
    overflow: hidden;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;

const QuestionItemBody = styled.div`
    padding: 15px 10px;
    background-color: #fafafa;
    h4 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 6px;
    }
`;

const QuestionItemHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    background-color: ${colors.gray[100]};

    h3 {
        font-size: 14px;
        font-weight: 600;
    }
`;

const Question = ({ name, values, onRemoveQuestion, onUp, onDown, showUp, showDown, index }) => {
    const type = values.questions[index].type;
    const typeLabel = {
        text: "Tekst",
        number: "Nummer",
        multiple_choice: "Meerkeuze"
    };

    const handleAddAnswer = (fields, type) => e => {
        fields.push({});
    };

    const handleRemoveAnswer = (fields, index) => e => {
        fields.remove(index);
    };

    return (
        <QuestionItem>
            <QuestionItemHeader>
                <h3>{typeLabel[type]}</h3>
                <ButtonGroup>
                    {showUp && (
                        <IconButton
                            backgroundColor="white"
                            aria-label="Vraag omhoog"
                            onClick={onUp}
                            icon={FiChevronUp}
                            size="xs"
                        />
                    )}
                    {showDown && (
                        <IconButton
                            backgroundColor="white"
                            aria-label="Vraag omlaag"
                            icon={FiChevronDown}
                            onClick={onDown}
                            size="xs"
                        />
                    )}
                    <IconButton
                        variantColor="red"
                        aria-label="Verwijderen"
                        icon={FiTrash2}
                        onClick={onRemoveQuestion}
                        size="xs"
                    />
                </ButtonGroup>
            </QuestionItemHeader>
            <QuestionItemBody>
                <Stack spacing="3">
                    <InputFormControl
                        placeholder="Vraag"
                        size="sm"
                        name={`${name}.question`}
                    ></InputFormControl>
                    {type == "multiple_choice" && (
                        <FormControl marginTop="12px">
                            <h4>Antwoorden</h4>
                            <FieldArray name={`${name}.answers`}>
                                {({ fields }) => (
                                    <Stack spacing={3}>
                                        {fields.map((name, index) => (
                                            <Flex key={index}>
                                                <InputFormControl
                                                    placeholder="Vraag"
                                                    flexGrow="1"
                                                    marginRight="10px"
                                                    size="sm"
                                                    name={`${name}.answer`}
                                                ></InputFormControl>

                                                <IconButton
                                                    aria-label="Verwijder antwoord"
                                                    icon={FiTrash2}
                                                    onClick={handleRemoveAnswer(fields, index)}
                                                    size="sm"
                                                />
                                            </Flex>
                                        ))}
                                        <Button
                                            onClick={handleAddAnswer(fields)}
                                            size="sm"
                                            alignSelf="flex-end"
                                        >
                                            Toevoegen
                                        </Button>
                                    </Stack>
                                )}
                            </FieldArray>
                        </FormControl>
                    )}
                </Stack>
            </QuestionItemBody>
        </QuestionItem>
    );
};
const VisitorReportsSettings = () => {
    const [isSaving, setIsSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [data, setData] = useState({
        questions: []
    });

    useLocationChange(() => {
        async function fetch() {
            setLoading(true);
            var res = await Axios.get("/api/report");

            if (!res.data) {
                setData({ questions: [] });
                return;
            }
            setData(res.data.content);
            setLoading(false);
        }
        fetch();
    });

    const handleAddQuestion = (fields, type) => e => {
        fields.push({
            type: type
        });
    };

    const handleRemoveQuestion = (fields, index) => e => {
        fields.remove(index);
    };

    const onSubmit = async values => {
        setIsSaving(true);

        var res = await Axios.post("/api/report", values);

        window.localStorage.setItem("visitor_report", JSON.stringify(res.data));

        toast({
            title: "Rapport",
            description: "Repport opgeslagen.",
            status: "success",
            isClosable: true
        });

        setIsSaving(false);
    };

    const handleUp = (fields, idx) => e => {
        fields.swap(idx, idx - 1);
    };
    const handleDown = (fields, idx) => e => {
        fields.swap(idx, idx + 1);
    };

    return (
        <SettingsPanelForm
            title="Bezoekrapport"
            onSubmit={onSubmit}
            initialValues={data}
            mutators={{
                ...arrayMutators
            }}
            actions={
                <ButtonGroup size="sm" marginLeft="auto">
                    <Button
                        type="submit"
                        leftIcon={FiCheck}
                        isLoading={isSaving}
                        variantColor="green"
                        loadingText="Opslaan"
                    >
                        Opslaan
                    </Button>
                </ButtonGroup>
            }
            render={({ handleSubmit, submitting, dirty, pristine, values }) => (
                <>
                    {loading && (
                        <Spinner
                            thickness="2px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="red.500"
                            size="md"
                        />
                    )}

                    {!loading && (
                        <Stack>
                            <FieldArray name="questions">
                                {({ fields }) => (
                                    <>
                                        {fields.map((name, index) => (
                                            <Question
                                                key={index}
                                                name={name}
                                                index={index}
                                                values={values}
                                                showUp={index != 0}
                                                showDown={index != fields.length - 1}
                                                onUp={handleUp(fields, index)}
                                                onDown={handleDown(fields, index)}
                                                onRemoveQuestion={handleRemoveQuestion(
                                                    fields,
                                                    index
                                                )}
                                            />
                                        ))}
                                        <ButtonGroup alignSelf="flex-end">
                                            <Button
                                                onClick={handleAddQuestion(fields, "text")}
                                                leftIcon={FiPlus}
                                                size="sm"
                                            >
                                                Tekst
                                            </Button>
                                            <Button
                                                onClick={handleAddQuestion(fields, "number")}
                                                leftIcon={FiPlus}
                                                size="sm"
                                            >
                                                Nummer
                                            </Button>
                                            <Button
                                                onClick={handleAddQuestion(
                                                    fields,
                                                    "multiple_choice"
                                                )}
                                                leftIcon={FiPlus}
                                                size="sm"
                                            >
                                                Meerkeuze
                                            </Button>
                                        </ButtonGroup>
                                    </>
                                )}
                            </FieldArray>
                        </Stack>
                    )}
                </>
            )}
        />
    );
};

export { VisitorReportsSettings };
